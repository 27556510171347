import { Component, HostListener, OnDestroy  } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MsgdialogService } from './msgdialog/msgdialog.service';
import { ApiService } from './core/api.service';
import { Router } from '@angular/router';
import { Event } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  template: '  <app-header></app-header>  <router-outlet></router-outlet> ',
  styleUrls: ['./app.component.sass'],

})
export class AppComponent {
  title = 'Gate ProData';
  constructor(public msgdialogService: MsgdialogService,  public apiService: ApiService, private router: Router){
  }


  @HostListener('window:Key', ['$event'])
  keyEvent(event: KeyboardEvent) {
     console.log('Evento do Teclado',event);
    if (event.code === 'Enter') {

      event.stopImmediatePropagation();
    }

  }
 

  @HostListener('window:beforeunload', ['$event'])
   WindowEvent(event: BeforeUnloadEvent) {
     this.apiService.logOut();
     
     event.returnValue = 'Você tem certeza disso?';
     this.apiService.logOut();
   }
   
   onBeforeUnload(): void {
      this.apiService.logOut();
      
   }

}
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-root',
  templateUrl: './errordialog.component.html',
  styleUrls: ['./errordialog.component.css']
})
export class ErrorDialogComponent implements OnInit {

  title = 'Angular-Interceptor';
  public dialogRef: MatDialogRef<ErrorDialogComponent>;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    status: '',
    statusText: '';
  }) {}


  ngOnInit() {}
  onNoClick(): void {
    this.dialogRef.close();
  }

}

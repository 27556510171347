import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../core/api.service';
import { HomeComponent } from '../home/home.component';
//import { VERSION } from '../../environments/version';
import { ErrorDialogService } from '../errordialog/errordialog.service';
import jwt_decode from "jwt-decode";

import { ViewportScroller } from "@angular/common";
declare var require: any
//const FileSaver = require('file-saver');

import { window as win } from 'rxjs/operators';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  formLogin: FormGroup;
  invalidLogin = false;
  version = "2.5.0";

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, public errorDialogService: ErrorDialogService) {

  }
  hide = true;
  public data

  onSubmit() {


    if (this.formLogin.invalid) {
      return;
    }
    const loginPayload = {
      username: this.formLogin.controls.username.value,
      password: this.formLogin.controls.password.value


    };
    this.apiService.getToken(loginPayload).subscribe((response: any) => {
      console.log('SUCESS ', response.body)

      if (response.body.data !== null) {
        console.log('SUCESS ', response.body)
        this.apiService.setToken(response.body.data.token)
        this.apiService.getAuth(response.body.data.token).subscribe((response: any) => {
          this.router.navigate(['home']);
          this.apiService.isAutenticate = true;
          const tok = jwt_decode(response.data.token)
          this.apiService.setUser(tok)

        })

      }

      if (response.body.data === null) {
        console.log(' NO SUCESS ', response.body.success)
        console.log('resposta login 3', response.body.error.code)
        const dt2 = {
          status: response.body.error.code,
          statusText: response.body.error.message
        };
        localStorage.clear()
        this.errorDialogService.openDialog(dt2);
      }

    })
  }
  ngAfterViewInit() {



  }
  ngOnInit() {
    this.formLogin = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.required]
    });

    this.formLogin.controls.username.setValue('');
    this.formLogin.controls.password.setValue('');

  }

  time() {
    return this.data = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
  }
  /*
  @HostListener('win:Key', ['$event'])
  hidePsw(event) {
    let e1 : KeyboardEvent;
    let e2: MouseEvent;
    
    console.log('eventos', e1, e2);
    console.log('Evento do Teclado',event);
    if (e1.code === 'Enter') {
      this.hide = true
    }

    this.hide = false;
    
    console.log("click - button", event, event.code);
  }
  */
  openDoc(startPage: number) {
    const pdfUrl = '../../assets/mocks/Manual_ProData.pdf';
    window.open(pdfUrl + '#page=' + startPage, '_blank', '');
  }


}

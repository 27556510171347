

/* Evergreen browsers require these. 
import 'core-js/es6/reflect';
*/
// tslint:disable-next-line: max-line-length
/* IE10 and IE11 requires the following to support `@angular/animation`. ALL Firefox browsers require the following to support `@angular/animation`. */
import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 * You can include your own polyfills here (e.g. CSS custom properties).
 */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lista-paginada',
  templateUrl: './lista-paginada.component.html',
  styleUrls: ['./lista-paginada.component.sass']
})
export class ListaPaginadaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

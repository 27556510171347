import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MsgdialogComponent } from './msgdialog.component';


@Injectable()
export class MsgdialogService {
  public isDialogOpen = false;

  constructor(public dialog: MatDialog) { }
  baseUrl = 'gate/'
  //baseUrlDownload 
  openDialog(data): any {
    if (this.isDialogOpen) {
      return false;
    }
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(MsgdialogComponent, { data: {
      titulo: data.titulo,
      status: data.status,
      statusText : data.statusText,
      statusLink: data.statusLink
    } });
    dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      if(result == 200){
        this.dialog.closeAll()
      }
      
    });
  }


}

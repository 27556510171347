import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  NgZone
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  FormControlName,
} from "@angular/forms";
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse,
  HttpEventType,
  // tslint:disable-next-line: import-spacing
} from "@angular/common/http";
import {
  MatStepper,
  MatStepHeader,
  MatSnackBar,
  MatBottomSheet,
  MatBottomSheetRef,
  MatAutocompleteTrigger,
} from "@angular/material";
import {
  map,
  startWith,
  debounceTime,
  tap,
  finalize,
  switchMap,
  distinctUntilChanged,
} from "rxjs/operators";

import { Router } from "@angular/router";
import { ApiService } from "../core/api.service";
import { MsgdialogService } from "../msgdialog/msgdialog.service";
import { HelpService } from "../help/help.service";
import { Observable, from } from "rxjs";

import { Nucleo } from "../model/nucleo.model";
import { CPO1 } from "../model/cpo1.model";
import { CPO2 } from "../model/cpo2.model";
import { Servicos } from "../model/servicos.model";
import { User } from "../model/user.model";
import { Lista } from "../model/listaIt.model";
import { UploadFilesService } from 'src/app/core/upload-files.service';

@Component({
  selector: 'app-frm-admin',
  templateUrl: './frm-admin.component.html',
  styleUrls: ['./frm-admin.component.css']
})
export class FrmAdminComponent implements OnInit {

  constructor(
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public ngZone: NgZone,
    public httpClient: HttpClient,
    public msgdialogService: MsgdialogService,
    public helpservice: HelpService,
    public router: Router,
    public _snackBar: MatSnackBar,
    public _bottomSheet: MatBottomSheet,
    public uploadFile: UploadFilesService,
  ) { }

  public nucleos: Nucleo[] = [];
  public cpo1: CPO1[] = [];
  public cpo2: CPO2[] = [];
  public servicos: Servicos[] = [];
  public users: User;
  private nucleo: string;
  private Perfil: string;
  public lista: Lista[] = [];
  filteredITs: Observable<any[]>;

  filteredCpo1: Observable<any[]>;
  filteredCpo2: Observable<any[]>;
  filteredSrvTec: Observable<any[]>;
  /*
  
 
  */
  private form = {
    nucleo_gate: "",
    campo_1: "",
    campo_2: "",
    campo_1ds: "",
    campo_2ds: "",
    servTec: "",
    servTecDs: "",
    update: "",
  }
  ngOnInit() {
    this.apiService.getUser().subscribe((res: any) => {
      // console.log(res)
      this.users = res;
      this.Perfil = this.users.Perfil;
      // console.log('RESP: User', this.Perfil);
      // this.usuario = res.Nome;

    });
    this.httpClient
      .get<Lista[]>(this.apiService.baseUrl + "listaItsNF")
      .subscribe((res: any) => {
        this.lista = res;
      });

    this.filteredITs = this.formAdmin.controls.FCDelIt_itcn_dk.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterITS(value))
    );

    this.apiService.getNucleos().subscribe((res: Nucleo[]) => {
      this.nucleos = res;
      //this.setNuga();
      this.filteredCpo1 = this.formAdmin.controls.FCcpo1.valueChanges.pipe(
        startWith(""),
        map((value) => this._filterCpo1(value))
      );

      this.filteredCpo2 = this.formAdmin.controls.FCcpo2.valueChanges.pipe(
        startWith(""),
        map((value) => this._filterCpo2(value))
      );
      this.filteredSrvTec = this.formAdmin.controls.FCServTec.valueChanges.pipe(
        startWith(""),
        map((value) => this._filterSrvTec(value))
      );
    });

  }

  public _filterITS(value: string): Lista[] {
    const filterValue = value.toUpperCase();
    return this.lista.filter(
      (option) =>
        option.itcn_NR_INFOTECNICA.toUpperCase().indexOf(filterValue) > -1
    );
  }

  public _filterCpo2(value: string): CPO1[] {
    const filterValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    return this.cpo2.filter(
      (option) =>
        option.ds
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
    );
  }
  public _filterSrvTec(value: string): Servicos[] {
    const filterValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    return this.servicos.filter(
      (option) =>
        option.ds
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
    );
  }
  public _filterCpo1(value: string): any[] {
    const filterValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    return this.cpo1.filter(
      (option) =>
        option.ds
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
    );
  }

  public formAdmin = new FormGroup({
    FCnucleo: new FormControl("", Validators.required),
    FCcpo1: new FormControl(""),
    FCcpo1_DS: new FormControl(""),
    FCcpo2: new FormControl(""),
    FCcpo2_DS: new FormControl(""),
    FCServTec: new FormControl(""),
    FCServTec_DS: new FormControl(""),
    FCupdate: new FormControl(""),
    FcRadioVinc: new FormControl(""),
    FCDelIt_itcn_dk: new FormControl(""),
  });
  public setNuga() {

    if (this.nucleo !== "") {
      this.nucleos.filter((nuga) => {
        if (nuga.nome.toUpperCase().indexOf(this.nucleo.toUpperCase()) > -1) {
          this.formAdmin
            .get("FCnucleo")
            .setValue(parseInt(nuga.dk, nuga.dk.length - 1));
          this.getCpo(nuga.dk);
          this.apiService.getServTec(nuga.dk).subscribe((res: Servicos[]) => {
            this.servicos = res;
          });
        }
      });
    }
  }
  public getCpo(nucleo) {
    this.form.nucleo_gate = nucleo;
    this.apiService.getCpo1(0).subscribe((res: CPO1[]) => {
      this.cpo1 = res;
      console.log('CPO1 0 res', res)
    });
    if (this.formAdmin.get('FCnucleo').value != '') {
      if (nucleo !== "") {
        this.apiService.getServTec(0).subscribe((res: Servicos[]) => {
          this.servicos = res;
        });

      }
    }
    // console.log('CPO1 0', this.cpo1)
  }
  public openUpload() {
    this.uploadFile.openDialog()
  }
  public getCpo2(e) {
    const nugadk = this.formAdmin.get("FCnucleo").value;
    let cpo = "";
    this.cpo1.filter((res) => {
      if (res.ds.indexOf(e.option.value) > -1) {
        cpo = res.dk;
        this.form.campo_1 = cpo;
      }
    });

    this.apiService.getCpo2(0, 0).subscribe((res: CPO2[]) => {
      this.cpo2 = res;
    });
  }
  public setCpo2(e) {
    console.log("Set cpo2")
    const cpo2ds = e.value
    this.cpo2.filter((res) => {
      if (res.ds.indexOf(e.option.value) > -1) {
        this.form.campo_2 = res.dk;
      }
    });
  }
  public setSrvTec(e) {
    this.servicos.filter((res) => {
      if (res.ds.indexOf(e.option.value) > -1) {
        this.form.servTec = res.dk;
      }
    });
  }

  helpOpen(e) {
    let id = "";
    console.log('eee', e)
    if (e == false) {
      id = "@INSERIR"
    }
    if (e == true) {
      id = "@ATUALIZAR"
    }
    if (e == 'Vincular') {
      id = "@VINCULAR"
    }

    this.httpClient.get("assets/mocks/help.json").subscribe((data: any[]) => {
      data.map((res) => {
        if (res.id === id) {
          console.log(res)
          this.helpservice.openDialog(res);
        }
      });
    });
  }
  openSnackBar(e) {
    let id = "";
    console.log('eee', e)
    if (e == false) {
      id = "@INSERIR"
    }
    if (e == true) {
      id = "@ATUALIZAR"
    }
    if (e == 'Vincular') {
      id = "@VINCULAR"
    }

    this.httpClient.get("assets/mocks/help.json").subscribe((data: any[]) => {
      data.map((res) => {
        if (res.id === id) {
          console.log(res)
          this._snackBar.open(res.statusText, res.titulo, {
            duration: 4000,
          });
        }
      });
    });

  }

  onSubmit() {
    const formDataOut = new FormData();

    this.form.campo_1ds = this.formAdmin.get('FCcpo1_DS').value;
    this.form.campo_2ds = this.formAdmin.get('FCcpo2_DS').value;
    this.form.servTecDs = this.formAdmin.get('FCServTec_DS').value;
    this.form.update = this.formAdmin.get('FCupdate').value;
    if (this.formAdmin.get('FCupdate').value == 'Vincular') {
      this.form.update = 'false';
    } else {
      this.form.update = this.formAdmin.get('FCupdate').value;
    }
    formDataOut.append("form", JSON.stringify(this.form));
    console.log('form', this.form);


    this.apiService
      .InsertCpos(formDataOut)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log(HttpEventType.Sent)
            break;
          case HttpEventType.ResponseHeader:
            if (event.status !== 200) {
              document
                .getElementById("progress")
                .setAttribute("mode", "determinate");
            }
            break;



          case HttpEventType.Response:
            // console.log('Http Envent Resposnse', HttpEventType.Response)
            const dt2 = {
              titulo: "Sucess",
              status: 200,
              statusText: event.body.status,
            };
            if (event.status !== 200) {
              document
                .getElementById("progress")
                .setAttribute("mode", "determinate");
            }
            // console.log('Status event', event.status);
            this.msgdialogService.openDialog(dt2);
            setTimeout(() => {
              this.router.navigate(["lista"]);
              //this.progressBar = 0;
            }, 3000);
            break;
        }
        // console.log('Evento', HttpEventType.Response);
      });

  }

  deleteIt() {


    this.apiService.deletaIt(this.formAdmin.get('FCDelIt_itcn_dk').value)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log(HttpEventType.Sent)
            break;
          case HttpEventType.ResponseHeader:
            if (event.status !== 200) {
              document
                .getElementById("progress")
                .setAttribute("mode", "determinate");
            }
            break;

          case HttpEventType.Response:
            // console.log('Http Envent Resposnse', HttpEventType.Response)
            const dt2 = {
              titulo: "Sucess",
              status: 200,
              statusText: event.body.status,
            };
            if (event.status !== 200) {
              document
                .getElementById("progress")
                .setAttribute("mode", "determinate");
            }
            if (event.status == 200) {
                this.httpClient
                    .get<Lista[]>(this.apiService.baseUrl + "listaItsNF")
                    .subscribe((res: any) => {
                        this.lista = res;
                });
            }

            // console.log('Status event', event.status);
            this.msgdialogService.openDialog(dt2);
            setTimeout(() => {
              this.router.navigate(["lista"]);
              //this.progressBar = 0;
            }, 3000);
            break;
        }


      });

  }

  deleteRel(){
    this.form.servTec
    this.form.nucleo_gate
    this.form.campo_1
    this.httpClient.post(this.apiService.baseUrl + "/deletRelServ?nuga=" + this.form.nucleo_gate + "&serv=" + this.form.servTec +"&cpo1="+this.form.campo_1,history)
    
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import {ApiService} from './core/api.service';
import {RedirectRouter} from './core/redirect-router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import {ReactiveFormsModule, FormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FrmCadastroItComponent } from './frm-cadastro-it/frm-cadastro-it.component';
import {ListaComponent} from './lista/lista.component'
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material/material.module';
import { MatTableFilterModule } from 'mat-table-filter';
import { LayoutComponent } from './layout/layout.component';
import { AgmCoreModule } from '@agm/core';
import { NgxCurrencyModule } from 'ngx-currency';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { HeaderComponent } from './header/header.component';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ErrorDialogComponent } from './errordialog/errordialog.component';

import { ErrorDialogService } from './errordialog/errordialog.service';
import { MsgdialogService } from './msgdialog/msgdialog.service';
import {HelpService} from './help/help.service';
import { EditarComponent } from './editar/editar.component';
import { ListaPaginadaComponent } from './lista-paginada/lista-paginada.component';
import { MsgdialogComponent } from './msgdialog/msgdialog.component';
import { FrmeditarComponent } from './frmeditar/frmeditar.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { HelpComponent } from './help/help.component';
import { FrmCadastroCposComponent } from './frm-cadastro-cpos/frm-cadastro-cpos.component';
import { FrmAdminComponent } from './frm-admin/frm-admin.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { DndDirective } from './directves/dnd.directive';
import { ManualComponent } from './manual/manual.component';
import { AnaliseComponent } from './analise/analise.component';
import { MatDialog } from '@angular/material';


registerLocaleData(ptBr);
export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    HomeComponent,
    FrmCadastroItComponent,
    ListaComponent, LayoutComponent, HeaderComponent, ErrorDialogComponent, EditarComponent,
    ListaPaginadaComponent, MsgdialogComponent, FrmeditarComponent, HelpComponent, FrmCadastroCposComponent, FrmAdminComponent, UploadFilesComponent, DndDirective, ManualComponent, AnaliseComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    MatTableFilterModule,
    FlexLayoutModule,
    NgxCurrencyModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA1ei1HJkyPn_cL_1oD_JLcvfNPyV-vFSI',
      libraries: ['places']

    }),
    MatGoogleMapsAutocompleteModule,

  ],
  providers: [ ApiService, ErrorDialogService, MsgdialogService, HelpService, LoginComponent, RedirectRouter,UploadFilesComponent, { provide: LOCALE_ID, useValue: 'pt' } ,
 { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true} ],
 entryComponents: [ErrorDialogComponent, MsgdialogComponent, HelpComponent, UploadFilesComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }



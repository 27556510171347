import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpComponent } from './help.component'
import { from } from 'rxjs';
import * as help from 'src/assets/mocks/help.json';

import { HttpClient } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';

export interface DataSend {
  id: string;
  titulo: string;
  status: string;
  statusText: string;
}
@Injectable({
  providedIn: 'root'
})
export class HelpService {
  dialogResult: string;
  public isDialogOpen = false;
  constructor(public dialog: MatDialog, private httpClient: HttpClient
  ) { }

  openDialog(data): any {
    if (this.isDialogOpen) {
      return false;
    }

    this.isDialogOpen = true;



    console.log('DataJson', data)
    const dialogRef = this.dialog.open(HelpComponent, {
      width: '400px',height:'auto',
      data: {
        id: data.id,
        titulo: data.titulo,
        status: data.status,
        statusText : data.statusText
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog closed: ${result}`);
      this.dialogResult = result;
    })
  }
}

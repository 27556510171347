import { Component, OnInit, ViewChild, ElementRef, Inject, Injectable } from '@angular/core';
import {
  HttpEventType,
  HttpResponse,
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,

} from '@angular/common/http';
import { Observable, AsyncSubject } from 'rxjs';
import { MatTableDataSource } from "@angular/material/table";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { MsgdialogService } from "../msgdialog/msgdialog.service";
import { SelectionModel } from "@angular/cdk/collections";
import { FormBuilder, FormGroup, FormControl, FormControlName } from '@angular/forms';
import { ApiService } from "../core/api.service";
import { N } from '@angular/cdk/keycodes';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { utf8Encode } from '@angular/compiler/src/util';



@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})

export class UploadFilesComponent implements OnInit {

  constructor(public apiService: ApiService, public dialog: MatDialog, public msgdialogService: MsgdialogService,
    @Inject(MAT_DIALOG_DATA) public data: { ID: string, NR: string }) {
    this.dataTableFiles = [];

  }
  Inject
  baseUrl = 'gate/api/';

  progressBar = 0;
  selectedFiles: FileList;
  newFiles: FileList;
  progressInfos = [];
  message = '';
  listOfFiles: any[] = [];

  fileInfos: Observable<any>;
  ArrayOfSelectedFile: any[] = [];
  dadosArquivo = [];
  dadosArquivoSend = [];
  disableSend = false;
  form = {
    ITAN_IN_TP_ARQUIVO: "",
    ITAN_NM_ARQUIVO: "",
    ITAN_IN_SIGILO: "",
    ITAN_NR_ORDEM: "",
    ITAN_ARQUIVO_ANEXO: File,
  };
  dados: any = [];
  FormSend = [];
  FRM = new Array;
  public formData = new FormData();
  public uploadForm = new FormGroup({
    FcAnexo: new FormControl(""),
  })
  dataTableFiles = [];

  displayedColumns = [
    "select",
    "position",
    "delete",
    "Arquivo",
    "Tamanho",
  ];
  ImageBaseData: string | ArrayBuffer = null;
  dataSourceFiles = new MatTableDataSource<Element>(this.dataTableFiles);
  selectionFile = new SelectionModel<Element>(true, []);

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    console.log('onFileDropped', $event)
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event) {
    console.log('fileBrowseHandler', $event)
    this.prepareFilesList($event);

  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    console.log('DELETE FILE')
    this.files.splice(index, 1);
    this.dados.splice(index,1)
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  async prepareFilesList(files: Array<any>) {
    const frm = new FormData();
    for (const item of files) {
      //item.progress = 0;


      this.files.push(item);

      //console.log('item', item)
    }
    for (let file of files) {

      file.sigilo = "N";
      file.nome = file.name;

      this.dadosArquivo.push(file)
     
      
     // let base64: String;
      let base64: string | ArrayBuffer = null;
      let arq: string;
      let me = this;
      //let file = files[0];
      let reader = new FileReader();
      //async getMatFormFieldDuplicatedHintError
      let fileContent: any
      fileContent = await this.readFile(file)
      
      fileContent =  fileContent = fileContent.split('base64,')[1];
      //.substr(fileInput.target.files[0].name.lastIndexOf(".") + 1)
      this.dados.push({
        itdk: this.data.ID,
        nmArquivo: file.nome.toString(),
        extencao: file.nome.toString().substr(file.nome.toString().lastIndexOf(".") + 1),
        tipoArquivo: "P",
        sigilo: "N",
        arquivo: fileContent

      });
      

      console.log('DADOS', this.dados)    
    }
    this.formData.append("form", JSON.stringify(this.form))    
    
  }
  csvContent: string;
  
  readFile(file) {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = () => {     
        const result = (fileReader.result);
        return resolve(result)
        
      };
      fileReader.onerror = () => {
        reject(fileReader.error);
      };
      fileReader.readAsDataURL(file);
    });
  }
  setAnexo(i, e, valor) {
    console.log('setAnexo(i)', i)
    
    this.dadosArquivo.forEach((item) => {
      const index: number = this.dadosArquivo.findIndex((i) => i === item);
      console.log('FcAnexo', this.uploadForm.controls.FcAnexo.value)
      console.log('item', item);

      this.form.ITAN_NM_ARQUIVO = item.name;
      this.form.ITAN_IN_SIGILO = "N";
      if(e=="ordem"){
        if(index == i){
          this.dados[i].nordem = valor
        }
      }
       if (e == "tipo") {  
        if (index == i ) {
          if(valor == true){
            this.dados[i].tipoArquivo = "N";
          }
          if(valor == false){
            this.dados[i].tipoArquivo = "P";
          }
        }
    
      
      }
      if (this.uploadForm.controls.FcAnexo.value == true)
        this.form.ITAN_IN_TP_ARQUIVO = "N";
      else
        this.form.ITAN_IN_TP_ARQUIVO = "P";

      this.form.ITAN_ARQUIVO_ANEXO = item;
    });

    //this.FormSend.push(this.form)
    console.log('DADOS', this.dados)

  }

  ngOnInit() {
    //this.fileInfos = this.uploadService.getFiles();
  //  console.log(this.data.ID)
  //  console.log(this.data.NR)

  }
  onSubmit() {
    //const formData = new FormData();

  }
  sendFiles() {
    const formDataOut = new FormData();
    formDataOut.append("form", JSON.stringify(this.form));
    if (this.formData.getAll.length > 0) {
      //console.log('FormSend', this.FormSend)
     // console.log('FRM', this.FRM)
       this.disableSend = true; 
      console.log('dados', this.dados)
      this.apiService.addnexos(this.dados, this.data.ID).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log(HttpEventType.Sent)
            break;
          case HttpEventType.ResponseHeader:
            console.log('HttpEventType.ResponseHeader:', HttpEventType.ResponseHeader)
            if (event.status !== 200) {
              //this.disableButton = false;
              document
                .getElementById("progress")
                .setAttribute("mode", "determinate");
              //this.progressBar = 0;
            }
            break;

          case HttpEventType.UploadProgress:
            this.progressBar = Math.round((100 * event.loaded) / event.total);
            // console.log(event.total, event.total);
            console.log(`Uploaded! ${this.progressBar}%`);
            //return `File "${this.fileData.name}" is ${this.progressBar}% uploaded.`;
            break;

          case HttpEventType.ResponseHeader:
            if (event.status === 500) {
              const dt2 = {
                status: event.status,
                statusText: 'O Erro interno do Servidor'
              };

              console.log('token 500')
              this.msgdialogService.openDialog(dt2);

            }
            break;
          case HttpEventType.Response:
            console.log('Http Envent Resposnse', HttpEventType.Response);
            console.log('Event cod', event.body.cod);

            if (event.body.cod === 12) {
              const dt2 = {
                titulo: "Sucess",
                status: 200,
                statusText: event.body.status,
              };
              this.msgdialogService.openDialog(dt2);
              console.log('this.msgdialogService.', this.msgdialogService)
              
              if (this.msgdialogService.isDialogOpen == false) {
                this.dialog.closeAll()
              }
            }


            if (event.status !== 200) {
              this.dialog.closeAll()
              document.getElementById("progress").setAttribute("mode", "determinate");
              const dt2 = {
                titulo: "Error",
                status: event.status,
                statusText: event.statusText,
              };
              this.msgdialogService.openDialog(dt2);
            }
            setTimeout(() => {
              this.progressBar = 0;
            }, 3000);
            break;
        }
      })
    }
    console.log('FFFFFF', this.formData.forEach.name);
  }
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

}

export interface Element {
  Arquivo: string;
  position: number;

}

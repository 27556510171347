import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../core/api.service';
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse
  // tslint:disable-next-line: import-spacing
} from '@angular/common/http';
import { map, window, tap, retry } from 'rxjs/operators';
import { MsgdialogService } from '../msgdialog/msgdialog.service';
import { User } from '../model/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  constructor(public router: Router, public apiService: ApiService, private httpClient: HttpClient,
    public msgdialogService: MsgdialogService) {

  }
  baseUrl = 'gate/api/';
  baseUrlLogin = 'gate/'
  logado: boolean;
  public usuario: string;
  public Perfil: string;
  public users: User;
  nucleo: string;
  userAutentic;
  httpOptions = {
    headers: new HttpHeaders({
      Host: 'h-apps.mprj.mp.br',
      Connection: 'Keep-alive',
      observe: 'response'
    })
  };

  ngOnInit() {
    /*
    this.apiService.getUser().subscribe((res: any) => {
      // console.log(res)
      this.users = res;      
      this.Perfil = this.users.Perfil;
      //this.usuario = res.Nome;
      
    });
    */

  }

  // time() {
  //  console.log('this.usuario', this.apiService.getUsu(), this.userAutentic )




  ngAfterViewInit(): void {
    // this.apiService.logOut();

  }

  redirect(obj: string) {

    if (obj === 'logout') {
      this.sair();

    } else {
      this.router.navigate([obj]);
    }


  }
  sair() {
    this.httpClient.request<any>('post', this.baseUrlLogin + 'user/logout', { observe: 'response' })
      .pipe(retry(2))
      .subscribe((resp) => {
        if (resp.status === 200) {
          this.router.navigate(['/login']);
        }
      });

  }


}

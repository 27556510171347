import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';

import { ErrorDialogService } from '../errordialog/errordialog.service';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpHeaderResponse
} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, retry, window } from 'rxjs/operators';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IfStmt } from '@angular/compiler';
import {LoginComponent} from '../login/login.component'

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private router: Router, private apiservice: ApiService, public errorDialogService: ErrorDialogService,
                public login: LoginComponent) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       
         const cloned = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.apiservice.getLocalToken()}`
            }
          });
                    
        return next.handle(cloned).pipe(
            
            retry(2),
            map((event: HttpEvent<any>) => {

                ////console.log('Evento Type', event);
                if (event instanceof HttpResponse) {
                    
                    //console.log('Evento Type 2', event);
                    //console.log('router', this.router.url)   

                    this.apiservice.isAutenticate = false;
                    if (event.status === 200) {
                        // //console.log('Evento 200', event);
                        this.apiservice.isAutenticate = true;
                        

                    } else {
                        sessionStorage.clear();
                        this.apiservice.isAutenticate = false;
                    }

              
                   
                }
                if(event instanceof HttpHeaderResponse){
                    if (event.status === 500 ) {
                        const dt2 = {
                            status: event.status,
                            statusText: 'O Erro interno do Servidor'
                        };
                        
                        ////console.log('token 500', this.apiservice.getLocalToken())
                        this.errorDialogService.openDialog(dt2);
                        
                    }
                }

                return event;

            }
            ),            
            catchError((error: HttpErrorResponse) => {
                let data = {};
                ////console.log("Error ", error)
                ////console.log("event ", HttpResponse)
                data = {
                    reason: error && error.error.reason ? error.error.reason : '',
                    status: error.status,

                };
                if (error.status === 500 ) {
                    const dt2 = {
                        status: error.status,
                        statusText: 'O Erro interno do Servidor'
                    };                    
                    if (this.router.url === '/cadastro' || this.router.url === '/lista') {
                        dt2.status = error.status
                        dt2.statusText = 'A sessão expirou.'
                        this.errorDialogService.openDialog(dt2);
                        this.apiservice.isAutenticate = false;
                        this.router.navigate(['login']);
                    }else{
                        //console.log('token 500', this.apiservice.getLocalToken())
                        this.errorDialogService.openDialog(dt2);

                    }
                    
                    
                    
                    
                }
                
                if (error.status === 504 ) {
                    const dt2 = {
                        status: error.status,
                        statusText: 'O Servidou demorou muito para responder, verifique a conexão com a internet'
                    };

                    this.errorDialogService.openDialog(dt2);
                    this.router.navigate(['login']);
                }

                if (error.status === 0 ) {
                    const dt2 = {
                        status: error.status,
                        statusText: 'Sem resposta do servidor, verifique a sua conexão de internet.'
                    };

                    this.errorDialogService.openDialog(dt2);
                    this.router.navigate(['login']);
                }

                
                if (error.status === 401) {
                    
                    const dt2 = {
                        status: '',
                        statusText: ''
                    };
                    /*sessionStorage.setItem('LoginActive',this.LoginActive ) */


                    if(this.router.url === '/login' ){

                        dt2.status = error.status.toString()
                        dt2.statusText = 'Usuário ou senha inválido.'
                        
                        this.apiservice.isAutenticate = false;
                        this.errorDialogService.openDialog(dt2);

                    }else {
                        if(this.router.url === '/cadastro' || this.router.url === '/lista'){
                            dt2.status = error.status.toString()
                            dt2.statusText = 'A sessão expirou.'
                            this.errorDialogService.openDialog(dt2);
                            this.apiservice.isAutenticate = false;
                            this.router.navigate(['login']);
                        }

                    }if(this.router.url.indexOf('editar') > -1){
                            dt2.status = error.status.toString()
                            dt2.statusText = 'A sessão expirou.'
                            this.errorDialogService.openDialog(dt2);
                            this.apiservice.isAutenticate = false;
                            this.router.navigate(['login']);

                    }


                } else {
                    //console.log("Erro 502", error.statusText)
                    const dt2 = {
                        status: error.status,
                        statusText: error.error.status
                    };
                    this.errorDialogService.openDialog(dt2);
                }
                return throwError(error);
            }));
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

export interface DialogData {
  id: string;
  titulo: string;
  status: string;
  statusText: string;
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.sass']
})


export class HelpComponent implements OnInit {

  public dialogRef: MatDialogRef<HelpComponent>;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    titulo: '',
    status: '',
    statusText: '';
  }
  ) {}


  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}

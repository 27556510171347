import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { MsgdialogService } from "../msgdialog/msgdialog.service";
import { ApiService } from "../core/api.service";
import { ErrorDialogService } from '../errordialog/errordialog.service';
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse,
  HttpEventType,
  // tslint:disable-next-line: import-spacing
} from "@angular/common/http";
@Component({
  selector: 'app-msgdialog',
  templateUrl: './msgdialog.component.html',
  styleUrls: ['./msgdialog.component.css']
})
export class MsgdialogComponent implements OnInit {

  public dialogRef: MatDialogRef<MsgdialogComponent>;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    titulo: '',
    status: '',
    statusText: '';
    statusLink:'';
  }, public dialog: MatDialog, private apiService: ApiService, public httpClient: HttpClient, public errorDialogService: ErrorDialogService) { }
  headers = ["TIPO", "NOME", "DOWNLOAD", " EXCLUIR"];

  datafile:any
  files =[];
  ngOnInit() {
    this.datafile = this.data.statusText
    this.files = this.datafile.anexos;
  }
  onNoClick(): void {
    this.dialogRef.close();
    this.dialog.closeAll();
  }
  delete(itan_ITCN_DK,itan_DK){
    console.log(itan_ITCN_DK,itan_DK)  
    this.apiService.deleteAnexos(itan_ITCN_DK,itan_DK) .subscribe((event: HttpEvent<any>) => {
      switch (event.type) {        
        case HttpEventType.Sent:
           //console.log(HttpEventType.Sent)
          break;
        case HttpEventType.ResponseHeader:
          console.log('evento case 1', event)    
          console.log('evento  case 1 ', event)  
          const dt1 = {
            titulo: "Sucesso",
            status: 200,
            statusText: "Arquivo excluido",
          };  
          if (event.status == 200) {            
             
              this.errorDialogService.openDialog(dt1)
          }
          break;
        
        case HttpEventType.Response:
         // console.log('evento case 2', event)    
          console.log('evento  case 2 body', event.body)    

          // console.log('Http Envent Resposnse', HttpEventType.Response)
          const dt2 = {
            titulo: "Sucess",
            status: 200,
            statusText: event.body.status,
          };
          

          if (event.body.cod == 200) {
            //this.msgdialogService.openDialog(dt2);
            this.errorDialogService.openDialog(dt2)
          
          }
         
      }
      this.files = this.files.filter(d => d.itan_DK != itan_DK)
    });
  }

  public redirectToDownLoad = (codigo: string, itcn_nm_Arquivo: string,itan_NM_EXTENCAO_ARQUIVO: string) => {
    console.log('Download',itcn_nm_Arquivo)
    this.apiService.downlodAnexo(codigo).subscribe((x) => {
      const newBlob = new Blob([x], { type: "application/pdf" });
      // tslint:disable-next-line: no-debugger
      const downloadURL = window.URL.createObjectURL(x);
      const link = document.createElement("a");
      link.href = downloadURL;
      const str = itcn_nm_Arquivo;
      let newstr = itcn_nm_Arquivo+"."+itan_NM_EXTENCAO_ARQUIVO
      //newstr = "IT " + newstr + "_" + new Date().getTime() + ".pdf";
      link.download = newstr;
      link.click();
      window.URL.revokeObjectURL(downloadURL);
      link.remove();
    });

  }
  
}
export interface Anexo {
  servico: string;
  position: number;
  opiniao: string;
}
import { Component, OnInit, AfterViewInit, ViewChild, NgZone, ElementRef, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, FormControlName } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../core/api.service';
import { User } from '../model/user.model';
import { Solicitante, IUSolicitanteResponse } from '../model/solicitante.model';
import { Nucleo } from '../model/nucleo.model';
import { CPO1 } from '../model/cpo1.model';
import { CPO2 } from '../model/cpo2.model';
import { Servicos } from '../model/servicos.model';
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse,
  HttpEventType
  // tslint:disable-next-line: import-spacing
} from '@angular/common/http';

import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { map, startWith, debounceTime, tap, finalize, switchMap, distinctUntilChanged } from 'rxjs/operators';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData, CurrencyPipe, formatCurrency } from '@angular/common';
import { MsgdialogService } from '../msgdialog/msgdialog.service';
import { HelpService } from '../help/help.service';
registerLocaleData(localePt);
import { Injectable } from '@angular/core';
import { IfStmt, ThrowStmt } from '@angular/compiler';
import { Lista } from '../model/listaIt.model';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { Observable, from } from 'rxjs';
import moment from 'moment';
import 'moment/locale/pt-br';

import { CurrencyMaskDirective } from 'ngx-currency';
@Injectable()
@Component({
  selector: 'app-frm-cadastro-cpos',
  templateUrl: './frm-cadastro-cpos.component.html',
  styleUrls: ['./frm-cadastro-cpos.component.css']
})
export class FrmCadastroCposComponent implements OnInit {

  constructor(public formBuilder: FormBuilder, public apiService: ApiService,
     public httpClient: HttpClient, public msgdialogService: MsgdialogService,
    public helpservice: HelpService, public router: Router){ }

    public cpo1: CPO1[] = [];
    public cpo2: CPO2[] = [];
    public nucleos: Nucleo[] = [];
    public servicos: Servicos[] = [];
    
    filteredCpo1: Observable<any[]>;
    filteredCpo2: Observable<any[]>;
    filteredSrvTec: Observable<any[]>;
    public formCadastroCpos = new FormGroup({
      FcDays: new FormControl(''),
      FcHoras: new FormControl(''),
      FcHorasTot: new FormControl('')
    })

    public days;
    public hours;
    public minutes;
    public seconds;
    public retorno;
    public daysFix;
  ngOnInit() {
      var now = moment(new Date()); //todays date
      var end = moment("2020-05-11 00:01:00"); // another date
      var duration = moment.duration(end.diff(now));
      var days2 = duration.asDays();
      var horas = duration.asHours();
      console.log('Dias',days2)
      console.log(horas)
      if(days2 < 0){
        this.router.navigate(['login']);
      }
    
     /* this.filteredCpo1 = this.formCadastroCpos.controls.FCcpo1.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCpo1(value))
      );


    this.filteredCpo2 = this.formCadastroCpos.controls.FCcpo2.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCpo2(value))
      );
    this.filteredSrvTec = this.formCadastroCpos.controls.FCServTec.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterSrvTec(value))
      );
    this.apiService.getNucleos()
      .subscribe((res: Nucleo[]) => {
        this.nucleos = res;
      
      }
      );  
  
   
      */
   
  

  }
  

  test() {
      moment.locale('pt-BR');
      moment.localeData('DD-MM-YYYY HH:MM:SS')
      let start = moment(new Date()); // some random moment in time (in ms)      
      let end = moment('2020-05-11 00:01:00'); // some random moment after start (in ms)    
      let duration = moment.duration(end.diff(start));
      let difff = end.diff(start);
      var duration2 = moment.duration(end.diff(start));
      var days2 = duration2.asDays();
      var horas = duration2.asHours();
      // console.log(days2)
      // console.log(horas)
     
      let f = moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
      //moment.utc(difff).format("HH:mm:ss");
      this.retorno = f;
     
      //Get hours and subtract from duration
      this.hours = duration.hours()
      duration.subtract(moment.duration(this.hours,'hours'));
        
      
      //Get Minutes and subtract from duration
      this.minutes = duration.minutes();
      duration.subtract(moment.duration(this.minutes,'minutes'));

      //Get seconds
      this.seconds = duration.seconds();
      // this.retorno = this.hours +':'+ this.minutes +':'+ this.seconds     
    
      this.days =  duration.days();
      // console.log('DIAS',this.days);
      duration.subtract(moment.duration(this.days,'days'));
      
      this.daysFix = moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
      // console.log('DIAS',this.daysFix);
      if (this.days <= 0 && this.hours <= '00' && this.minutes <= '00' && this.seconds <= '00'){
         this.router.navigate(['login']);
                    
      } else {        
          var days2 = duration.asDays();
          var horas = duration2.asHours();   
          var horas2 = horas.toString().replace('.',':')
          let hh =  this.days +' dias '+ this.retorno.toString().substring(0,2) + 'h ' + this.retorno.toString().substring(3,5) + 'min ' + this.retorno.toString().substring(6,8) + 's ';
          var seg = horas2.substring(6,8)
          this.formCadastroCpos.get('FcHoras').setValue(hh);
          this.formCadastroCpos.get('FcHorasTot').setValue(horas2.substring(0,6) + ':' + seg + ' Horas' );
          
  
      }     
 
  
}


  public _filterCpo2(value: string): CPO1[] {
    const filterValue = value.toUpperCase();
    return this.cpo2.filter(option => option.ds.toUpperCase().indexOf(filterValue) > -1);

  }
  public _filterSrvTec(value: string): Servicos[] {
    const filterValue = value.toUpperCase();
    return this.servicos.filter(option => option.ds.toUpperCase().indexOf(filterValue) > -1);

  }
  public _filterCpo1(value: string): any[] {
    const filterValue = value.toUpperCase();
    return this.cpo1.filter(option => option.ds.toUpperCase().indexOf(filterValue) > -1);
  }
}

import { Component, OnInit } from '@angular/core';

/**
 * @ignore 
 */
@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.css']
})
export class EditarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse
  // tslint:disable-next-line: import-spacing
} from '@angular/common/http';

import { Observable, of, throwError, BehaviorSubject } from 'rxjs/index';
import { retry, catchError, tap, map, retryWhen, filter, mergeMap } from 'rxjs/operators';

import { Solicitante, } from '../model/solicitante.model';
import { User } from '../model/user.model';
import { Nucleo } from '../model/nucleo.model';
import { Lista, Lista2 } from '../model/listaIt.model';
import { Tecnicos } from '../model/tecnicos.model';
import { FRMEDIT } from '../model/fromEdit.model';
import { MatSnackBar } from '@angular/material';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ErrorHandlerService } from '../core/error-handler.service';
import { CPO2 } from '../model/cpo2.model';
import { encode } from 'punycode';
import jwt_decode from "jwt-decode";

@Injectable({ providedIn: 'root' })

export class ApiService {
  formData = new FormData();
  /*


  */

  private currentUserSubject: BehaviorSubject<FRMEDIT>;
  public currentUser: Observable<User>;
  public isAutenticate;
  httpOptions = {
    headers: new HttpHeaders({
      Host:'h-apps.mprj.mp.br',
      Connection: 'Keep-alive',
      observe: 'Response'
    })
  };
  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar, private router: Router) {
   
  }
  //AIzaSyCbydNyluzgtQOdMYgY6ZWLtek2uVCYvsI
  //apiKey = 'AIzaSyA1ei1HJkyPn_cL_1oD_JLcvfNPyV-vFSI'
  apiKey = 'AIzaSyCbydNyluzgtQOdMYgY6ZWLtek2uVCYvsI'
  baseUrl = 'gate/api/';
  baseUrlLogin = 'gate/';
  public token_autorize: any;
  public token: any;
  public Usuario: any;
  public Perfil: any;
  public users: User[] = [];
  public Geometry :any[] =[];
  public frmEdit: Observable<FRMEDIT>;
  public Arr: any[] = [];
  private tecnicos: Tecnicos[] = [];
  public authentication(loginPayload) {
    this.formData.append('username', loginPayload.username);
    this.formData.append('password', loginPayload.password);
    return this.httpClient.post(this.baseUrl + 'authentication',this.formData, {observe: 'response'})
  }

  public getUser(){
     return this.httpClient.get(this.baseUrl + 'getUser')
  }
  
  
  public getLatLong(adress){
    debugger
    let key = ",+CA&key="+ this.apiKey
    let Url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + adress + key
    return this.httpClient.get<any[]>(Url,  { responseType: 'json' })    
      .subscribe((res: any) => {
        this.Geometry = res.results        
    });  
  }

  public autenticateReturn() {
    return this.httpClient.get(this.baseUrl + 'authenticate',  {observe: 'response'})
  }
  /*
   pushSaveCalc(json: any){        
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');    
    headers.append('Accept', 'application/json');
    headers.append( 'responseType', 'text' )    
    console.log(json);
    const body = json
    return this.httpClient.post(this.baseUrl + 'web/saveCalc', body,{ responseType: 'text' });    
  }

  */

  public addnexos(json:any, dk){
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
    headers.append('Accept', 'application/json');
    headers.append('responseType', 'text')
    console.log('JOSN',json);
    const body = json    

    return this.httpClient.post(this.baseUrl + 'Addanexos?q=' + dk, body, {
      reportProgress: true,
      observe: 'events'});    

  }

  public deleteAnexos(it:number,arq:number){
    console.log("URL",this.baseUrl + 'deleteAnexos?it='+it+'&arq='+arq)
    return this.httpClient.delete(this.baseUrl + 'deleteAnexos?it='+it+'&arq='+arq, {
      reportProgress: true,
      observe: 'events'});    
  }

  getSolicitantes() {
    return this.httpClient.get<Solicitante[]>(this.baseUrl + 'listaSolicitantes').pipe(
      retry(1),
      catchError(this.handleError));
  }

  getSolicitantes2(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.baseUrl + 'listaSolicitantes');
  }

  getNucleos() {
    return this.httpClient.get<Nucleo[]>(this.baseUrl + 'listaNucleos').pipe(
      retry(1),
      catchError(this.handleError));
  }

  getCPF(cpf: string) {  
    return this.httpClient.get<any[]>(this.baseUrlLogin + 'Cidadao/getCPF?q=' + cpf, { responseType: 'json' })
  }

  getListaIts() {
    return this.httpClient.get<Lista2[]>(this.baseUrl + 'listaIts', { responseType: 'json' });
  }

  getListaIts2() {
    return this.httpClient.get<Lista2[]>(this.baseUrl + 'listaIts');
  }

  getListMyIts(){
    return this.httpClient.get<any[]>(this.baseUrl + 'ListaItsMatric', { responseType: 'json' });

  }

  getUnidades(){
    return this.httpClient.get('http://apps.mprj.mp.br/mpedia/api/dados/lupa/lp_entidade_unidades_saude', this.httpOptions);   
  }


  getListaItsPgs(sizenum, pagnum) {

    let endquestion;

    if ((sizenum != 0) && (pagnum == 0)) {
      endquestion = 'size=' + sizenum;
      // listpag = `/gate/api/listaItsPg?${endquestion}`
    } else {
      // tslint:disable-next-line: triple-equals
      if ((sizenum != 0) && (pagnum != 0)) {
        const pag = '&page=' + pagnum;
        const siz = 'size=' + sizenum;
        endquestion = siz.concat(pag);

      }
    }

    return this.httpClient.get<Lista[]>(this.baseUrl + 'listaItsPg?size=' + sizenum).pipe(
      retry(1),
      catchError(this.handleError));
  }

  setUsu(user){
    this.Usuario = user
  }
  setPerfil(perfil){
    
    this.Perfil = perfil
    console.log('RESP Perfil:', this.Perfil)
  }
  getUsu(){
    return this.Usuario;
  }
  getPerfil(){
    
    return this.Usuario.Perfil;
    
  }
  // FAZ O DOWNLOAD DO BLOB COM BASE NO ID
  downlodIt(dk: string) {  
    return this.httpClient.get(this.baseUrl + 'downloadPDF/' + dk, { responseType: 'json' });
  }
  //detalharIt
  detalharIt(dk:string){
    return this.httpClient.get(this.baseUrl + 'detalharIt/' + dk, { responseType: 'json' });
  }
  // FAZ A BUSCA NO BACK-END PELO CAMPO1 COM BASE NO NUCLEO 
  getCpo1(nucleo) {
    return this.httpClient.get(this.baseUrl + 'listaCpo1/' + nucleo)
      .pipe(
        retry(1),
        catchError(this.handleError));
  }

  // FAZ A BUSCA NO BACK-END PELO CAMPO2 COM BASE NO NUCLEO E CAMPO1 
  getCpo2(nucleo, cpo1) {
    return this.httpClient.get<CPO2[]>(this.baseUrl + 'listaCpo2/' + nucleo + '/' + cpo1)
      .pipe(
        retry(1),
        catchError(this.handleError));
  }
  // FAZ A BUSCA NO BACK-END PELO SERVICO TECNICO COM BASE NO NUCLEO
  getServTec(nucleo) {
    return this.httpClient.get(this.baseUrl + 'listaServicos/' + nucleo)
      .pipe(
        retry(1),
        catchError(this.handleError));
  }
  getServTecMult(ServiTec) {
    ////console.log("Serviços", ServiTec)
    return this.httpClient.get(this.baseUrl + 'listaServicosMult/' + ServiTec)
      .pipe(
        retry(1),
        catchError(this.handleError));
  }
  getCpo1Mult(Nucleos) {
    return this.httpClient.get(this.baseUrl + 'listaCpo1Mult/' + Nucleos)
      .pipe(
        retry(1),
        catchError(this.handleError));
  }


  downlodIt2(dk: string): Observable<Blob> {

    return this.httpClient.get(this.baseUrl + 'downloadPDF/' + dk, { responseType: 'blob' });
  }

  downlodAnexo(codigo: string): Observable<Blob> {
    return this.httpClient.get(this.baseUrlLogin + '/Cidadao/downloadAnexoPDF?codigo=' + codigo, { responseType: 'blob' });
  }
  getNumSei(username: string) {
    
    username = "'" + username + "'" + '/';
    const getUrl = this.baseUrlLogin +'Cidadao/getNumSeiPsql' 
    return this.httpClient.get(getUrl, { responseType: 'json' });
  }
  getValidaMPRJ(MPRJ){
    ////console.log('MPRJ',MPRJ)
    return this.httpClient.get(this.baseUrl + 'validaMPRJ?q=' + MPRJ, this.httpOptions);   

  }
  getMprjDupli(MPRJ){

    return this.httpClient.get(this.baseUrl + 'getMprjCad?q=' + MPRJ, { responseType: 'json' }).pipe(
      retry(1));

  
  }
  

  getSolict(numProto: string) {
    
    numProto = "'" + numProto + "'" + '/';
    const UrlgetSol = this.baseUrlLogin + 'Cidadao/getSei2/' + numProto;
    // //console.log('UrlgetSol',UrlgetSol)
    return this.httpClient.get(UrlgetSol);

  }

  getCep(cep) {
   
    return this.httpClient.get(`https://viacep.com.br/ws/${cep}/json/`).pipe(
      retry(1)
    );

  }
  getTecnicos() {
        return this.httpClient.get(this.baseUrl + 'listaTecnicos', { responseType: 'json' });   
  }
  getListaOpinioesTecnicas() {
    return this.httpClient.get(this.baseUrl + 'listaOpinioesTecnicas', { responseType: 'json' });     
  }

  cadastrarIt(e) {
    return this.httpClient.post(this.baseUrl + 'cadastrarIt', e, {
      reportProgress: true,
      observe: 'events'});

  }


  InsertCpos(e) {
    return this.httpClient.post(this.baseUrl + 'inserirCpos', e, {
      reportProgress: true,
      observe: 'events'});

  }
  // Post de Edição da IT params Multipar e id da IT (itcn_dk); 
  editarIt(e, itcndk) {
    return this.httpClient.post(this.baseUrl + 'editarIt/' + itcndk, e, {
      reportProgress: true,
      observe: 'events'});

  }
  // Get para buscar o form de Edição da IT.
  getFrmEditar(obj) {
      return this.httpClient.get(this.baseUrl + 'formEditarIt/' + obj, {responseType: 'json'})
      /*.pipe(
          map(res => {
            // //console.log('Response ', res);
            return res;
          })
      );
      */
  }
  deletaIt(itcndk) {
    ////console.log('delete', itcndk)
    return this.httpClient.delete(this.baseUrl + 'deletIt/' + itcndk,  {
      reportProgress: true,
      observe: 'events'})
    
  }


  logOut() {
    return this.httpClient.post(this.baseUrlLogin + 'user/logout', { Accept: '*/*' });
   

  }

 
  handleError(error) {

    let errorMessage = '';
    let resultado = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {

      if (error.status === 401) {
        // this.router.navigate(['login']);
        // //console.log('Erro 401', error.status);

      }
 
      resultado = error.message.substring(error.message.indexOf('api') + 3);
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message} \nUrl: ${error.url}`;
    }
    return throwError(errorMessage);
  }

  public getAutorize(token){
   
    this.token = token;    
    this.token_autorize = jwt_decode(token);
    
   console.log('Receive token decode', this.token_autorize);

    let httpHeaders = new HttpHeaders();
    httpHeaders.append('Authorization', 'Bearer ' + token);    
    httpHeaders.append('Content-Type', 'application/json');    
    httpHeaders.append("Accept", "*/*")

    var reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      "Accept": "*/*"

    });
  
    let body = { "application": this.token_autorize.appId, "role": this.token_autorize.roles[0] }    
    let options2 = { headers: reqHeader};       
    return this.httpClient.post(this.baseUrlLogin, body, options2)
    
  }

  public getAuth(token): Observable<any>{
    //const login = { "username": "anderson.valgas", "password": "bx11ts" }
    //console.log('Body Token', token);
    this.token_autorize = jwt_decode(token);
    let httpHeaders = new HttpHeaders();
    httpHeaders.append('Authorization', 'Bearer ' + token);
    httpHeaders.append('Content-Type', 'application/json');
    httpHeaders.append("Accept", "*/*")
    
    var reqHeader = new HttpHeaders({      
      "Authorization": "Bearer " + token,
      "Content-Type": "application/json",
      "Accept": "*/*"
    });

    let body = { "application": this.token_autorize.appId, "role": this.token_autorize.roles[0] } //{"application": "GATE", "role": "Gestor"}//

    //console.log('header', reqHeader);
    let options2 = { headers: httpHeaders };
    //console.log('BODY', body);
    return this.httpClient.post(this.baseUrlLogin + "user/authorizeRole", body, options2)
  }

  /** 
   * Arbutus Micro Service Requests
   * Using proxy.config.json to rewrite address
   */
  arbutusServerUrl = '/arbutus/';

  getApps() {
    return this.httpClient.get(this.arbutusServerUrl, { responseType: 'json' });
  }

  getAnalise({...params}) {
    return this.httpClient.get(this.arbutusServerUrl + params.app, { responseType: 'json' });
  }

  createAnalise(tipoAnalise, data){
    return this.httpClient.post(this.arbutusServerUrl + tipoAnalise, data, {
      reportProgress: true,
      observe: 'events',
    });
  }
    
  public getLocalToken(): string {    
    
    return localStorage.getItem('BearerToken');

  }
  public getToken(loginPayload){

    return this.httpClient.post(this.baseUrlLogin +"user/login", loginPayload, { observe: 'response' })
  }
  

  public setToken(token){
    localStorage.setItem('BearerToken',token)
  }
  public isAuthenticated(): boolean {
    // get the token
    const token = this.getLocalToken();
    // return a boolean reflecting 
    // whether or not the token is expired
    return true;
  }
  public setUser(token){       
    console.log(token)    
    this.Usuario = localStorage.setItem('Usuario',token.name);
    localStorage
    //console.log('USUSARIO',this.Usuario)
    this.Perfil = localStorage.setItem('Perfil',token.roles[0]);
    this.isAutenticate = true;
    //console.log(this.Usuario)
  }
  getListaTpReneg(){
    return this.httpClient.get(this.baseUrl +"listTpReneg",  { responseType: 'json' })
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse,HttpRequest, HttpEventType } from  '@angular/common/http';  
import { map } from  'rxjs/operators';
import { ApiService } from "../core/api.service";
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {UploadFilesComponent} from "../upload-files/upload-files.component";

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {
  public isDialogOpen = false;
  constructor(private http: HttpClient, public apiService: ApiService,public dialog: MatDialog) { }
  
  openDialog(): any {
    if (this.isDialogOpen) {
      return false;
    }
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(UploadFilesComponent );
    dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      console.log(result)
    });
  }
  
  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.apiService.baseUrl}/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    console.log(formData)
    return this.http.request(req);
  }
  
  getFiles(): Observable<any> {
    return this.http.get(`${this.apiService.baseUrl}/files`);
  }
}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../core/api.service';
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse
  // tslint:disable-next-line: import-spacing
} from '@angular/common/http';

import { User } from '../model/user.model';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  usuario='';
  nucleo='';
  logado='';
  public users: User[]
  constructor(private router: Router, public apiService: ApiService, private httpClient: HttpClient) {  }
  ngAfterViewInit(): void {
    this.apiService.getUser().subscribe(
      (resp: any)=> {
        this.apiService.setUsu(resp.Nome)
        this.usuario = resp.Nome;
        this.apiService.setPerfil(resp.Perfil)
        this.users = resp;
        return this.users;
      });

  }
  ngOnInit() {
    this.logado = this.apiService.isAutenticate;
    //this.apiService.getUser();
    /*setTimeout(() => {
      this.apiService.autenticate();
    }, 900000);
    if(this.router.url === '/home'){
    }
    */

  }

  redirect(obj: string) {

    if (obj === 'logout') {
      return this.apiService.logOut()

    } else {
      console.log(obj);
      this.router.navigate([obj]);
    }

  }
}



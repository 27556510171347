import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  FormControlName,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  HttpEvent,
  HttpEventType,
  // tslint:disable-next-line: import-spacing
} from "@angular/common/http";
import { ApiService } from "../core/api.service";
import { MsgdialogService } from "../msgdialog/msgdialog.service";
import { Protoloco } from "../model/protocolo.model";
import { User } from "../model/user.model";
import { Tecnicos } from "../model/tecnicos.model";


@Component({
  selector: 'app-analise',
  templateUrl: './analise.component.html',
  styleUrls: ['./analise.component.css']
})
export class AnaliseComponent implements OnInit {
  
  public formCadastro = new FormGroup({
    FcTipoAnalise: new FormControl("", [Validators.required]),
    FcNumeroSei: new FormControl("",[Validators.required]),
    FcArquivo: new FormControl("",[Validators.required]),
  })
  public numSei: Protoloco[] = [];
  public tiposAnalise: any = [];
  public users: User[] = [];
  public tecnicos: Tecnicos[] = [];
  public filterTec: Tecnicos[] = [];
  public login: string;
  public usuario: string;
  public nucleo: string;
  public isAutenticate = false;
  public file :File = null;
  public progressBar = 0;
  public analiseDados : any = [];
  public planilha : string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public msgdialogService: MsgdialogService,

  ) { }

  public setUserLogin(){
    let nome = this.usuario;
    let matricula;
    this.tecnicos.filter((option) => {
      if (option.nome.indexOf(nome) > -1) {
        matricula = option.matric;
      }
    });
    let options = this._filterTecnicos(nome);
    //this.formCadastro.get("FcTec").setValue(options[0].nome);
  }

  public _filterTecnicos(value: string): any[] {
    const filterValue = value.toUpperCase();
    return this.tecnicos.filter(
      (option) => option.nome.toUpperCase().indexOf(filterValue) > -1
    );
  }

  ngOnInit() {

    this.apiService.getUser().subscribe((res: any) => {
      this.users = res;
      this.login = res.Login;
      this.usuario = res.Nome;
      this.nucleo = res.Orgao;
    });
    /*
    this.apiService.autenticate().subscribe((res: any[]) => {
      this.users = res;
      this.isAutenticate = true;
      this.setUserLogin();
      return res;
    });
    */

    this.apiService.getTecnicos().subscribe((res: Tecnicos[]) => {
      this.tecnicos = res;
      this.filterTec = this.tecnicos;
      //this.AddRowsTec("Auto");
      let nome;
      let matricula;
      nome = this.usuario;
      this.tecnicos.filter((option) => {
        if (option.nome.indexOf(nome) > -1) {
          matricula = option.matric;
        }
      });
      //this.formCadastro.controls.FcTec.setValue(nome)
    });

    this.apiService
    .getNumSei("RONALDO.VELLOSO")
    .subscribe((res: Protoloco[]) => {
      this.numSei = res;
      this.numSei = this.numSei.sort(function(a,b) {return (a.protocolo > b.protocolo) ? 1 : ((b.protocolo > a.protocolo) ? -1 : 0);} );
    });

    this.apiService.getApps().subscribe((res: any) => {
      this.tiposAnalise = res;
      this.planilha = this.tiposAnalise[0].model;
    });


    this.apiService.getAnalise({'app':'medicamentos'}).subscribe((res: any) => {
      this.analiseDados = res;
    });

  }

  onFileChange(event:any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.formCadastro.patchValue({
        fileSource: this.file
      });
    }
  } 

  public addAnalise(){
    let data = new FormData();
    let tipoAnalise = this.formCadastro.get("FcTipoAnalise").value
    data.append('login', this.login);
    data.append('sei', this.formCadastro.get("FcNumeroSei").value);
    data.append('file', this.file);
    this.apiService.createAnalise(tipoAnalise, data)
    .subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          // console.log(HttpEventType.Sent)
          break;
        case HttpEventType.ResponseHeader:
          if (event.status !== 200) {
            let onLoading = false;
            document
              .getElementById("progress")
              .setAttribute("mode", "determinate");
            this.progressBar = 0;
          }
          break;

        case HttpEventType.UploadProgress:
          this.progressBar = Math.round((100 * event.loaded) / event.total);
          // console.log(event.total, event.total);
          // console.log(`Uploaded! ${this.progressBar}%`);
          return `Arquivo "${this.file.name}" foi ${this.progressBar}% enviado.`;
          break;

        case HttpEventType.Response:
          // console.log('Http Envent Resposnse', HttpEventType.Response)
          let dt2 = {
            titulo: "Sucess",
            status: 200,
            statusText: event.body.status+"!",
          };
          if (event.status === 200 && event.body.status !== 'Agendado') {
            dt2 = {
              titulo: "Aviso",
              status: 201,
              statusText: event.body.status+"!",
            };
          }
          if (event.status !== 200) {
            document
              .getElementById("progress")
              .setAttribute("mode", "determinate");
          }
          this.msgdialogService.openDialog(dt2);
          if (event.status === 200 && event.body.status === 'Agendado') {
            setTimeout(() => {
              this.router.navigate(["home"]);
            }, 3000);
          }
          break;
      }
    });
  }

}

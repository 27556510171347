
import { UploadFilesComponent } from 'src/app/upload-files/upload-files.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { ViewportScroller } from "@angular/common";
import { MatAccordion } from '@angular/material/expansion';
import { T } from '@angular/cdk/keycodes';
import { Nucleo } from "../model/nucleo.model";
import { CPO1 } from "../model/cpo1.model";
import { CPO2 } from "../model/cpo2.model";
import { Servicos } from "../model/servicos.model";
import { MsgdialogService } from "../msgdialog/msgdialog.service";
import { MatDialog } from '@angular/material';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  FormControlName,
} from "@angular/forms";
import { ApiService } from "../core/api.service";
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.css']
})

export class ManualComponent implements OnInit {
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  panelOpenState = true;
  public bloco1 = false;
  public bloco2 = false;
  public bloco3 = false;
  public bloco4 = false;
  public bloco5 = false;
  public bloco6 = false;
  public bloco7 = false;
  public bloco8 = false;
  public bloco9 = false;
  public bloco22 = false;
  public nucleos: Nucleo[] = [];
  public cpo1: CPO1[] = [];
  public cpo2: CPO2[] = [];
  public servicos: Servicos[] = [];
  constructor(private scroller: ViewportScroller, private router: Router,  public apiService: ApiService, public dialog: MatDialog) {}
  public formCadastro = new FormGroup({
    FCnumeroSei: new FormControl(""),
    FCnumeroSei2: new FormControl(""),
    Fcsolicitante: new FormControl("", Validators.required),
    FCnucleo: new FormControl("", Validators.required),
    FCnucleoMult: new FormControl("", Validators.required),
    FCmprj: new FormControl("", Validators.required),

    FCcpo1: new FormControl(""),
    FCcpo2: new FormControl(""),
    FCServTec: new FormControl(""),
    FCopTec: new FormControl(""),

    FCTec: new FormControl("", Validators.required),
    cadastro: new FormControl(""),
    FcCkeck: new FormControl(""),
    /*           */
    FcEndRadio: new FormControl(""),
    FcEnd: new FormControl(""),
    FcEndLogradouro: new FormControl(""),
    FcEndCidade: new FormControl(""),
    FcEndBairro: new FormControl(""),
    FcEndNumero: new FormControl(""),
    FcEndComplemento: new FormControl(""),
    FcEndCep: new FormControl(""),
    FcEndLat: new FormControl(""),
    FcEndLong: new FormControl(""),

    FcEndLatNum: new FormControl(""),
    FcEndLongNum: new FormControl(""),

    FcNvl_dano: new FormControl(""),
    FcDano: new FormControl(""),
    FcComp: new FormControl(""),

    FcValCompDano: new FormControl(""), // Valor Sobre Preço
    FcValIndDano: new FormControl(""), // Valor Indicio no dano
    FcAtualValDano: new FormControl(""), // Atualização de Dano ao Erário

    FcInDano: new FormControl(""),
    FcAtDano: new FormControl(""),
    FcCnpj: new FormControl(""),
    FcNmInves: new FormControl(""),

    FcTec: new FormControl(""),

    FctotContra: new FormControl(""),
    FctotContraCd: new FormControl(""),
    FcCidade: new FormControl(""),
    FcValDano: new FormControl(""),
    searchControl: new FormControl(""),
    FcArquivo: new FormControl(""),
    FcDanoS: new FormControl(""),
    FcCheckDano: new FormControl(""),
    FcTpContrato: new FormControl(""),
    Fcund: new FormControl(""),
    FcCompTec: new FormControl(""),
    //
    FcMprjError: new FormControl(""),
    Fcdzero: new FormControl(""),
  });
  public NucleoTec
  ngOnInit() {
    this.accordion.openAll();
    this.scroller.scrollToPosition([0,0]);
    this.apiService.getNucleos().subscribe((res: Nucleo[]) => {
      this.nucleos = res;      
    });
    
  }
  openModal(dk : string){
    console.log('DK',dk)
    
    this.dialog.open(UploadFilesComponent,{
      "width": '800px',
      "maxHeight": '200vh',
      data: { name: 'austin' },
      
      "autoFocus": false
    });
  }
  public getCpo(nucleo) {
    this.NucleoTec = nucleo
    let ServiTec = this.formCadastro.get("FCnucleo").value;
    let Cpo1mult = this.formCadastro.get("FCnucleo").value;
    //let ServiTec = this.formCadastro.get("FCnucleo").value;
    this.apiService.getServTecMult(ServiTec)
    .subscribe((res: Servicos[]) => {
      this.servicos = res;
      console.log("services", res)
    });
    /*
    this.apiService.getCpo1(this.NucleoTec).subscribe((res: CPO1[]) => {
      this.cpo1 = res;
      console.log("Cpos 1", res)
    });
    */
    console.log("NucleoTec 1", this.NucleoTec)
    console.log("Nucleo", nucleo)
    console.log("NucleoTec 2", ServiTec)
    let camps;
    if (this.NucleoTec === undefined || this.NucleoTec === ''){
      this.NucleoTec == nucleo;
      
    }
    //console.log("NucleoTec 2", this.NucleoTec)
    this.apiService.getCpo1(this.NucleoTec).subscribe((res: CPO1[]) => {
        this.cpo1 = res;
        console.log("Cpos 1", res)
    });
    
    ServiTec.map((nucleo: any) => {
      if (nucleo === 6 || nucleo === 7 || nucleo === 3 || nucleo === 4) {
     //   this.CheckNuga = true;
      } else {
      //  this.CheckNuga = false;
      }
      if (nucleo === 2 || nucleo === 1) {
      //  this.CheckNugaSaude = true;
      }
    })    

  }

  public getCpo2(cpo) {

    console.log('cpo1 dk', cpo)
    /*let cpo = "";
    this.cpo1.filter((res) => {
      if (res.ds.indexOf(e.option.value) > -1) {
        cpo = res.dk;
      }
    });
    */
    
    //this.form.campo_1 = cpo;
    this.apiService.getCpo2(0, cpo).subscribe((res: CPO2[]) => {
      this.cpo2 = res;
    });
  }

  downloadPdf(pdfUrl: string, pdfName: string ) {
    //const pdfUrl = './assets/sample.pdf';
    //const pdfName = 'your_pdf_file';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  openDoc( startPage: number ) {
    const pdfUrl = '../../assets/mocks/Manual_ProData.pdf';
    window.open(pdfUrl + '#page=' + startPage, '_blank', '');
  }
  setIndex(bloco) {
    //this.accordion.openAll();
    if (bloco == "1") {      
      if (this.bloco1 == false) {        
        this.bloco1 = true;
      }
      else if (this.bloco1 == true) {
        this.bloco1 = false;
      }
    }
    
    if (bloco == "2") {      
      if (this.bloco2 == false) {        
        this.bloco2 = true;
      }
      else if (this.bloco2 == true) {
        this.bloco2 = false;
      }
    }
    if (bloco == "3") {
      console.log("bloco 2", bloco)
      if (this.bloco3 == false) {        
        this.bloco3 = true;
      }
      else if (this.bloco3 == true) {
        this.bloco3 = false;
      }
    }
    if (bloco == "4") {
      console.log("bloco 2", bloco)
      if (this.bloco4 == false) {        
        this.bloco4 = true;
      }
      else if (this.bloco4 == true) {
        this.bloco4 = false;
      }
    }
    if (bloco == "5") {
      //this.scroller.scrollToAnchor("bloco54");    
      this.bloco5 = true;
      this.scroller.scrollToPosition([0,0])
      console.log("getScrollPosition ", this.scroller.getScrollPosition());
      this.scroller.scrollToAnchor('bloco54');
      console.log("getScrollPosition ", this.scroller.getScrollPosition());
      /*
      if (this.bloco5 == false) {        
        this.bloco5 = true;
        this.accordion.openAll();
        this.scroller.scrollToAnchor("bloco54");
      
      }
      else if (this.bloco5 == true) {
        this.bloco5 = false;      
      }
      */
    }
    if (bloco == "6") {
      //console.log("bloco 2", bloco)
      if (this.bloco6 == false) {        
        this.bloco6 = true;
      }
      else if (this.bloco6 == true) {
        this.bloco6 = false;
      }
    }
    if (bloco == "7") {
      //console.log("bloco 2", bloco)
      if (this.bloco7 == false) {        
        this.bloco7 = true;
      }
      else if (this.bloco7 == true) {
        this.bloco7 = false;
      }
    }
    if (bloco == "8") {
      console.log("bloco 2", bloco)
      if (this.bloco8 == false) {        
        this.bloco8 = true;
      }
      else if (this.bloco8 == true) {
        this.bloco8 = false;
      }
    }
    if (bloco == "9") {
      console.log("bloco 2", bloco)
      if (this.bloco9 == false) {        
        this.bloco9 = true;
      }
      else if (this.bloco9 == true) {
        this.bloco9 = false;
      }
    }
    if (bloco == "9") {
      console.log("bloco 2", bloco)
      if (this.bloco22 == false) {        
        this.bloco22 = true;
      }
      else if (this.bloco22 == true) {
        this.bloco22 = false;
      }
    }
  }
  
  open(){
    
  }
  scrollIntoView(elem: string) {
    //ScrollIntoView(elem: string) {
      this.router.navigate([], { fragment: "targetBlue" });
      console.log(elem);
      document.querySelector(elem).scrollIntoView({ behavior: 'smooth', block: 'start' });
   
  }
  

}

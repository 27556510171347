import { ManualComponent } from './manual/manual.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {FrmCadastroItComponent} from './frm-cadastro-it/frm-cadastro-it.component';
import { ListaComponent } from './lista/lista.component';
import {FrmeditarComponent} from './frmeditar/frmeditar.component';
import { EditarComponent } from './editar/editar.component';
import {FrmCadastroCposComponent} from './frm-cadastro-cpos/frm-cadastro-cpos.component'
import {RedirectRouter} from './core/redirect-router';
import { FrmAdminComponent } from './frm-admin/frm-admin.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { AnaliseComponent } from './analise/analise.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent },
  {path: 'home', component: HomeComponent },
  {path: 'cadastro', component: FrmCadastroItComponent },
  {path: 'cadastrodt/:dt', component: FrmCadastroItComponent },
  {path: 'lista', component: ListaComponent },
  {path: 'editar/:dk/:nr', component: FrmeditarComponent },
  {path: 'admin' , component: FrmAdminComponent},  
  {path:'manual', component: ManualComponent},
  {path:'analise', component: AnaliseComponent},
  { path: 'anexos', component: UploadFilesComponent },
  {
    path: 'video',
    canActivate: [RedirectRouter],
    component: RedirectRouter,
    data: {
      externalUrl: '/assets/video/index.html'
    }
  },
  {
    path: 'docs',
    canActivate: [RedirectRouter],
    component: RedirectRouter,
    data: {
      externalUrl: '/assets/docs/index.html'
    }
  },
  {path : '', component : LoginComponent},
  {path : '404', component : LoginComponent},
  {path : '**', component : LoginComponent}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

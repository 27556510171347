import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  FormControlName,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "../core/api.service";
import { User } from "../model/user.model";
import { Solicitante, IUSolicitanteResponse } from "../model/solicitante.model";
import { Nucleo } from "../model/nucleo.model";
import { CPO1 } from "../model/cpo1.model";
import { CPO2 } from "../model/cpo2.model";
import { Servicos } from "../model/servicos.model";
import { Tecnicos } from "../model/tecnicos.model";
import { Protoloco } from "../model/protocolo.model";
import { Observable, from } from "rxjs";
import { COMMA, ENTER, I } from "@angular/cdk/keycodes";
import {
  map,
  startWith,
  debounceTime,
  tap,
  finalize,
  switchMap,
  distinctUntilChanged,
} from "rxjs/operators";
import { OpTec } from "../model/opTec.model";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import {
  MatStepper,
  MatStepHeader,
  MatSnackBar,
  MatBottomSheet,
  MatBottomSheetRef,
  MatAutocompleteTrigger,
} from "@angular/material";

import {
  MapsAPILoader,
  MouseEvent,
  AgmMap,
  AgmCoreModule,
  GeocoderLocationType,
} from "@agm/core";
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  NgZone,
  ElementRef,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { GoogleMapsAPIWrapper } from "@agm/core/services";
import { SendData } from "../model/sendData.model";
import { isBoolean, isUndefined } from "util";
//import { } from "google-maps";
import { stringToKeyValue } from "@angular/flex-layout/extended/typings/style/style-transforms";
import * as prefeituras from "src/assets/mocks/prefeituras.json";
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse,
  HttpEventType,
  // tslint:disable-next-line: import-spacing
} from "@angular/common/http";

import {
  Location,
  Appearance,
  GermanAddress,
} from "@angular-material-extensions/google-maps-autocomplete";
import PlaceResult = google.maps.places.PlaceResult;

import localePt from "@angular/common/locales/pt";
import {
  registerLocaleData,
  CurrencyPipe,
  formatCurrency,
  UpperCasePipe,
} from "@angular/common";
import { MsgdialogService } from "../msgdialog/msgdialog.service";
import { HelpService } from "../help/help.service";
registerLocaleData(localePt);
import { Injectable } from "@angular/core";
import { IfStmt, ThrowStmt } from "@angular/compiler";
import { Lista } from "../model/listaIt.model";
import { TpReneg } from "../model/tpreneg.model";
import { trimTrailingNulls } from "@angular/compiler/src/render3/view/util";
import { ifStmt } from "@angular/compiler/src/output/output_ast";
import { CurrencyMaskDirective } from "ngx-currency";
import { ActivatedRoute } from "@angular/router";
import { UploadFilesService } from 'src/app/core/upload-files.service';
import { UploadFilesComponent } from 'src/app/upload-files/upload-files.component'
//import { debug } from "console";

@Injectable()
@Component({
  selector: "app-frm-cadastro-it",
  templateUrl: "./frm-cadastro-it.component.html",
  styleUrls: ["./frm-cadastro-it.component.css"],
})
export class FrmCadastroItComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public mapsAPILoader: MapsAPILoader,
    public ngZone: NgZone,
    public httpClient: HttpClient,
    public msgdialogService: MsgdialogService,
    public helpservice: HelpService,
    public router: Router,
    public uploadAnexos: UploadFilesService,
    public _snackBar: MatSnackBar,
    public _bottomSheet: MatBottomSheet
  ) {
    this.dataTable = [];
    this.dataTableCPF = [];
    this.dataTableTecnicos = [];
    this.mapsAPILoader = mapsAPILoader;
    this.ngZone = ngZone;
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    document.addEventListener(
      "keypress",
      (e) => {
        if (e.key === "Enter") {
          this.KeyPressEnter = true;
          return false;
        }
      },
      false
    );
    document.addEventListener(
      "keydown",
      (e) => {
        if (e.key === "Enter") {
          this.KeyPressEnter = true;
          return false;
        }
      },
      false
    );
  }


  form = {
    solicitante: "",
    numero_mprj: "",
    nucleo_gate: "",
    campo_1: "",
    campo_2: "",
    servicos_tecnicos: [],
    palavras_chave: [],
    local: "",
    cpfs_cnpjs: [],
    endereco: {},
    tecnicos: [],
    opiniao_tecnica: [],
    dano_erario: "",
    valor_dano: "",
    complemento_opiniao_tecnica: "",
    nvl_proced: null,
    tipoContrato: " ",
    nr_sei: "",
    nrItVinc: "",
    inCompAnal: "N",
    inAtualVal: "N",
    atualValDano: "", // Dano Atualizado
    indicioDano: "", // Indicio de Dano
    compDano: "", // Sobrebreço
    outrosDanos: "", // Outros Danos
    danoZero: "",
    renegocia:[],
    inreng:"",
    itcn_IN_TP_INFO:"", //Tipo de Informação (DT,IT,SI)
  };
  /*  */
  /*

  */
  selected ="";
  myControl = new FormControl();
  itcn_dt: any;

  ckeckEnd = false;
  checkLat = false;
  KeyPressEnter = false;

  checkDanoS = false;
  checkDanoAuto = false;

  checkInDanoS = true;
  checkInDanoN = false;
  checkContrato = false;
  totDano = 0;
  valordano = "";
  checkFcCompS = false;
  checkFcCompJp = false;
  checkFcCompN = false;
  CompOp = false;
  checkAtValS = true;
  checkAtValN = false;
  checkcomDanoS = true;
  ChechEditEnd = false;

  CheckEditEnd;
  amount = "";
  CheckNuga = false;
  CheckNugaSaude = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  // Variaveis de Validações de Cpo1, Solicitante, CPo2, e Serviços
  selectSoli;
  selectCpo1;
  selectCpo2;
  selectSrv;
  getMsg = 0;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  checkNivel = "";
  NvProcedimento = "";
  myVariableColor = "#505050";
  ServTec: string;
  Cnpj: string;
  NmInvest: string;
  uploadForm = this.formBuilder.group({
    avatar: [null],
  });
  public frm = new FormData();
  // public form = {};
  //[Validators.required, RequireMatch])
  public formCadastro = new FormGroup({
    FCnumeroSei: new FormControl(""),
    FCnumeroSei2: new FormControl(""),
    Fcsolicitante: new FormControl("", Validators.required),
    FCnucleo: new FormControl("", Validators.required),
    FCnucleoMult: new FormControl("", Validators.required),
    FCmprj: new FormControl("", Validators.required),

    FCcpo1: new FormControl(""),
    FCcpo2: new FormControl(""),
    FCServTec: new FormControl(""),
    FCopTec: new FormControl(""),

    FCTec: new FormControl("", Validators.required),
    cadastro: new FormControl(""),
    FcCkeck: new FormControl(""),
    /*           */
    FcEndRadio: new FormControl(""),
    FcEnd: new FormControl(""),
    FcEndLogradouro: new FormControl(""),
    FcEndCidade: new FormControl(""),
    FcEndBairro: new FormControl(""),
    FcEndNumero: new FormControl(""),
    FcEndComplemento: new FormControl(""),
    FcEndCep: new FormControl(""),
    FcEndLat: new FormControl(""),
    FcEndLong: new FormControl(""),


    FcEndLatNum: new FormControl(""),
    FcEndLongNum: new FormControl(""),

    FcNvl_dano: new FormControl(""),
    FcDano: new FormControl(""),
    FcComp: new FormControl(""),

    FcValCompDano: new FormControl(""), // Valor Sobre Preço
    FcValIndDano: new FormControl(""), // Valor Indicio no dano
    FcAtualValDano: new FormControl(""), // Atualização de Dano ao Erário

    FcInDano: new FormControl(""),
    FcAtDano: new FormControl(""),
    FcCnpj: new FormControl(""),
    FcNmInves: new FormControl(""),

    FcTec: new FormControl(""),

    FctotContra: new FormControl(""),
    FctotContraCd: new FormControl(""),
    FcCidade: new FormControl(""),
    FcValDano: new FormControl(""),
    searchControl: new FormControl(""),
    FcArquivo: new FormControl(""),
    FcDanoS: new FormControl(""),
    FcCheckDano: new FormControl(""),
    FcTpContrato: new FormControl(""),
    Fcund: new FormControl(""),
    FcCompTec: new FormControl(""),
    //
    FcMprjError: new FormControl(""),
    Fcdzero: new FormControl(""),
    FCRengIt: new FormControl(""),
    FCtpRengIt: new FormControl(""),
    FCtpInfo: new FormControl(""),
  });
  tpContrato: TipoContrato[] = [
    { value: "I", viewValue: "Insumo" },
    { value: "O", viewValue: "Obra" },
    { value: "S", viewValue: "Serviço" },
  ];

  displayedColumnsCPF = [
    "select",
    "position",
    "delete",
    "cpf_cnpj",
    "nm_contratada",
    "num_contratos",
    "num_contratos_dano",
    "val_dano",
  ];
  displayedColumnsTecnico = [
    "select",
    "position",
    "delete",
    "tecnico",
    "check",
  ];

  displayedColumns = [
    "select",
    "position",
    "delete",
    "servico",
    "opiniao",
    "tecnico",
    "check",
  ];
  dataTable = [];
  dataTableCPF = [];
  dataTableTecnicos = [];
  // tslint:disable-next-line: variable-name
  cpfs_cnpjs = [];
  servitec = [];
  endereco: any = {};
  OptecServ = [];
  tecnico = [];
  renegocia = [];
  dataSource = new MatTableDataSource<Element>(this.dataTable);
  dataSourceCPF = new MatTableDataSource<Element2>(this.dataTableCPF);
  dataSourceTecnico = new MatTableDataSource<ElementTec>(
    this.dataTableTecnicos
  );
  selection = new SelectionModel<Element>(true, []);
  selectionCPF = new SelectionModel<Element2>(true, []);
  selectionTecnico = new SelectionModel<ElementTec>(true, []);
  filteredOptions: Observable<string[]>;

  Vobj;
  vFun;
  public users: User[] = [];
  public promotorias: Solicitante[] = [];
  public cpo1: CPO1[] = [];
  public cpo2: CPO2[] = [];
  public nucleos: Nucleo[] = [];
  public tpReneg: TpReneg[] = [];
  public lista: Lista[] = [];
  public frmSendData: SendData;

  public tecnicos: Tecnicos[] = [];
  public filterTec: Tecnicos[] = [];
  public servicos: Servicos[] = [];
  public optec: OpTec[] = [];
  public numSei: Protoloco[] = [];
  public numSeiFilter
  progress = 0;
  isFirstStep: boolean;
  progressBar = 0;
  public message: string;
  isLoading = false;
  public isAutenticate = false;
  filteredSolicitante: Observable<Solicitante[]>;
  filteredCpo1: Observable<any[]>;
  filteredCpo2: Observable<any[]>;
  filteredSrvTec: Observable<any[]>;
  filteredUnidades: Observable<any[]>;
  filteredITs: Observable<any[]>;

  filterControlSEI = new FormControl('');
  filteredSEI:  Observable<any[]>;
  filteredTecnitos: Observable<any[]>;
  fileData: File = null;
  fileSize
  fileSizeMsg
  fileUnit
  //
  disbleNmInv = true;

  // Maps
  @ViewChild(AgmMap, { static: false }) map: AgmMap;

  @ViewChild("search", { read: true, static: false })
  @ViewChild("stepper", { static: false })
  stepper: MatStepper;
  @ViewChild("stepperHeader", { static: false }) stepperHeader: MatStepHeader;
  @ViewChild(MatAutocompleteTrigger, { static: false })
  trigger: MatAutocompleteTrigger;
  @ViewChild("input", { static: true }) autoTrigger: MatAutocompleteTrigger;
  public searchElementRef: ElementRef;
  public geoCoder: any;

  texto = "GATE - Grupo de Apoio Técnico Especializado";
  // latitude: number;
  // longitude: number;
  // zoom: number;
  address: string;
  usuario: string;
  nucleo: string;
  cc: string;
  firstFormGroup: FormGroup = this.formCadastro;

  secondFormGroup: FormGroup;
  dados = [];
  DataCity: any = [];
  DataSaude: any = [];
  DadosEndGo: any = [];
  dadosCPF = [];
  dadosTec = [];
  /*  */
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  public contrato: any;
  public validaMprj;
  public validaMPRJDupli;
  public NucleoTec
  public validaCPNJ;
  public disableButton;

  onSubmit() {

    this.disableButton = true;
    //this.apiService.autenticate();
    const formDataOut = new FormData();
    //this.form.nucleo_gate = this.formCadastro.get("FCnucleo").value;
    let mprj = this.formCadastro.get("FCmprj").value;
    mprj = mprj.replace(/\D/g, "");
    this.form.numero_mprj = mprj;
    // this.contrato = new RegExp(/^Contrato*/)
    this.form.cpfs_cnpjs = this.cpfs_cnpjs;

    if (this.endereco.tipo_end === "L") {
      this.endereco.latitude = this.formCadastro.get("FcEndLatNum").value;
      this.endereco.longitude = this.formCadastro.get("FcEndLongNum").value;
    }
    this.endereco.numero = this.formCadastro.get('FcEndNumero').value;
    this.endereco.complemento = this.formCadastro.get('FcEndComplemento').value.toUpperCase();
    this.endereco.complemento = this.formCadastro.get('FcEndComplemento').value;
    this.endereco.logradouro = this.formCadastro.get('FcEndLogradouro').value.toUpperCase();
    this.endereco.bairro = this.formCadastro.get('FcEndBairro').value.toUpperCase();

    if (this.endereco.logradouro === "") {
      if (this.endereco.tipo_end === "E" || this.endereco.tipo_end === "T") {
        this.endereco.logradouro = this.formCadastro.get('FcEndLogradouro').value.toUpperCase()
      }
    }
    let nrSeiFromatado = this.formCadastro.get('FCnumeroSei').value;
    nrSeiFromatado = nrSeiFromatado.replace(/\D/g, '');
    this.form.nr_sei = this.formCadastro.get('FCnumeroSei').value;
    const FcEndCidade = this.formCadastro.get('FcEndCidade').value;

    if (this.endereco.municipio === '' && FcEndCidade !== '') {
      this.DataCity.filter((option) => {
        if (option.Cidade.indexOf(FcEndCidade) > -1) {
          this.endereco.municipio = option.id;
        }
      });
    }


    if (this.endereco.cep !== '' && this.endereco.cep !== undefined) {
      console.log('CEP', this.endereco.cep)
      const str = this.endereco.cep;
      const newstr = str.replace('-', '');
      this.endereco.cep = newstr;
    }

    this.form.endereco = this.endereco;
    this.form.tecnicos = this.tecnico;
    this.form.servicos_tecnicos = this.servitec;
    let ServTecArr = this.removerDuplicadosComplexos(this.OptecServ);
    console.log("Serviços Tecnicos XXX",ServTecArr)
    this.form.opiniao_tecnica = this.OptecServ;
    this.form.tipoContrato = this.formCadastro.get("FcTpContrato").value;

    if (this.formCadastro.get("FcDano").value > 0) {
      this.form.dano_erario = "S";
      this.form.valor_dano = this.formCadastro.get("FcDano").value;
    }

    if (this.formCadastro.controls.FcDano.value === '' || this.formCadastro.controls.FcDano.value <= 0) {
      this.form.dano_erario = "N";
      this.form.valor_dano = "0.0";
    }


    this.form.complemento_opiniao_tecnica = this.formCadastro.get("FcCompTec").value;

    this.form.nrItVinc = this.formCadastro.get("FcComp").value;
    if (this.formCadastro.get("FcComp").value != "") {
      this.form.nrItVinc = this.formCadastro.get("FcComp").value;
      this.form.inCompAnal = "S";
    }
    if (this.formCadastro.get("FcNvl_dano").value === "M") {
      this.form.nvl_proced = "M";
    }

    if (this.formCadastro.get("FcNvl_dano").value === "E") {
      this.form.nvl_proced = "E";
    }
    this.form.atualValDano = this.formCadastro.get("FcAtualValDano").value;
    this.form.indicioDano = this.formCadastro.get("FcValIndDano").value;
    this.form.compDano = this.formCadastro.get("FcValCompDano").value;
    this.form.danoZero = this.formCadastro.controls.Fcdzero.value;
    //this.form.inreng = this.formCadastro.controls.FCRengIt.value;
    this.form.renegocia = this.renegocia;
    this.form.itcn_IN_TP_INFO = this.formCadastro.get("FCtpInfo").value
    formDataOut.append("form", JSON.stringify(this.form));
    formDataOut.append("file", this.fileData);
    console.log('form', this.form);


    this.apiService
      .cadastrarIt(formDataOut)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log(HttpEventType.Sent)
            break;
          case HttpEventType.ResponseHeader:
            if (event.status !== 200) {
              this.disableButton = false;
              document
                .getElementById("progress")
                .setAttribute("mode", "determinate");
              this.progressBar = 0;
            }
            break;

          case HttpEventType.UploadProgress:
            this.progressBar = Math.round((100 * event.loaded) / event.total);
            // console.log(event.total, event.total);
            // console.log(`Uploaded! ${this.progressBar}%`);
            return `File "${this.fileData.name}" is ${this.progressBar}% uploaded.`;
            break;

          case HttpEventType.Response:
            // console.log('Http Envent Resposnse', HttpEventType.Response)
            const dt2 = {
              titulo: "Sucess",
              status: 200,
              statusText: event.body.status,
            };
            if (event.status !== 200) {
              document
                .getElementById("progress")
                .setAttribute("mode", "determinate");
            }
            this.msgdialogService.openDialog(dt2);
            setTimeout(() => {
              this.router.navigate(["lista"]);
              this.progressBar = 0;
            }, 3000);
            break;
        }
      });

  }

  ngOnInit() {
    this.itcn_dt = this.route.snapshot.params.dt;



    this.zoom = 10;
    this.formCadastro.get("FcNmInves").disable();
    // this.setCurrentPosition();

    this.apiService.getUser().subscribe((res: any) => {
      console.log('get user', res)
      this.users = res;
      this.usuario = res.Nome;
      this.nucleo = res.Orgao;
      let nuregx;
      nuregx = new RegExp(/^NÚCLEO*/);
      console.log('REGEX',nuregx.test(this.nucleo))
      if (nuregx.test(this.nucleo)) {
        this.nucleo = this.nucleo;
      }else{
        this.nucleo = ""
    }
      console.log('setNUga', this.nucleo)
    });
    //this.filteredSEI = this.numSei;


    // Subscribe to filter input changes

    this.filteredSEI = this.formCadastro.controls.FCnumeroSei.valueChanges.pipe(
      startWith(""),
      map((filterValue) => this._filterSEI(filterValue))
    );

    this.filteredITs = this.formCadastro.controls.FcComp.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterITS(value))
    );

    this.filteredSolicitante = this.formCadastro.controls.Fcsolicitante.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    this.filteredCpo1 = this.formCadastro.controls.FCcpo1.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterCpo1(value))
    );

    this.filteredCpo2 = this.formCadastro.controls.FCcpo2.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterCpo2(value))
    );
    this.filteredSrvTec = this.formCadastro.controls.FCServTec.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterSrvTec(value))
    );
    //
    this.apiService
      .getNumSei("RONALDO.VELLOSO")
      .subscribe((res: Protoloco[]) => {
        this.numSei = res;
        this.numSei = this.numSei.sort(function (a, b) { return (a.protocolo > b.protocolo) ? 1 : ((b.protocolo > a.protocolo) ? -1 : 0); });
      });

    this.filteredUnidades = this.formCadastro.controls.Fcund.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterUnidades(value))
    );
    this.filteredTecnitos = this.formCadastro.controls.FcTec.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterTecnicos(value))
    );
    this.httpClient
      .get("assets/mocks/prefeituras.json")
      .subscribe((data: any[]) => {
        this.DataCity = data;
      });

    /*this.apiService.autenticate().subscribe((res: any[]) => {
      this.users = res;
      this.isAutenticate = true;
      this.setUserLogin();
      return res;
    });
    */

    this.httpClient
      .get<Lista[]>(this.apiService.baseUrl + "listaItsPg?size=10000")
      .subscribe((res: any) => {
        this.lista = res.content;
      });


    this.httpClient.get("assets/mocks/saude.json").subscribe((data: any[]) => {
      this.DataSaude = data;
    });

    this.apiService.getSolicitantes().subscribe((res: Solicitante[]) => {
      this.promotorias = res;
    });

    this.apiService.getNucleos().subscribe((res: Nucleo[]) => {
      this.nucleos = res;
      this.setNuga();
      this.setUserLogin();
    });
    this.apiService.getListaTpReneg().subscribe((res: TpReneg[]) => {
      this.tpReneg = res;
      console.log("Reneg " + this.tpReneg)
    })
    this.apiService.getTecnicos().subscribe((res: Tecnicos[]) => {
      this.tecnicos = res;
      this.filterTec = this.tecnicos;
      //this.AddRowsTec("Auto");
      let nome;
      let matricula;
      nome = this.usuario;
      this.tecnicos.filter((option) => {
        if (option.nome.indexOf(nome) > -1) {
          matricula = option.matric;
        }
      });
      //this.formCadastro.controls.FcTec.setValue(nome)
    });

    this.apiService.getListaOpinioesTecnicas().subscribe((res: OpTec[]) => {
      this.optec = res;
    });

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();

      this.geoCoder = new google.maps.Geocoder();
      const autoCompleteInput = document.getElementById("endereco");
      // this.setCurrentLocationLatLong()
      /*
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // google.maps.places.PlaceResult = autocomplete.getPlace();
          // const place: google.maps.places.PlaceResult == autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude);
        });
      });

      */
    });


  }


  ngAfterViewInit(): void {
    this.stepper.linear = true;
    this.stepper.selectedIndex = 0;
    this.stepper.selected.completed = false;
    this.ckeckEnd = true;
  }
  public setUserLogin() {
    console.log("teste Usuario", this.usuario)
    let nome = this.usuario;
    let matricula;
    this.tecnicos.filter((option) => {
      if (option.nome.indexOf(nome) > -1) {
        matricula = option.matric;
      }
    });
    this.formCadastro.get("FCRengIt").setValue('N')
    this.formCadastro.get("FCtpInfo").setValue('IT')
    let options = this._filterTecnicos(nome);
    // console.log("teste select", options)
    this.formCadastro.get("FcTec").setValue(options[0].nome);
  }
  // Get Current Location Coordinates
  public setNuga() {
    //debugger
    let nuregx;
    nuregx = new RegExp(/^NÚCLEO*/);
    console.log('REGEX',nuregx.test(this.nucleo))
    if (nuregx.test(this.nucleo)) {
      this.nucleo = this.nucleo;
    }else{
      this.nucleo = ""
    }
    console.log('setNUga', this.nucleo)

    if (this.nucleo === "") {

      this.apiService.getUser().subscribe((res: any) => {
         console.log('get user', res)
        this.users = res;
        this.usuario = res.Nome;

      });

    }
    if (this.nucleo !== "") {
      this.nucleos.filter((nuga) => {
        if (nuga.nome.toUpperCase().indexOf(this.nucleo.toUpperCase()) > -1) {
          this.formCadastro.controls.FCnucleo.setValue([nuga.dk]);
          this.form.nucleo_gate = nuga.dk
          this.NucleoTec = nuga.dk
          let ServiTec = this.formCadastro.get('FCnucleo').value;
          this.apiService.getServTecMult(ServiTec)
            .subscribe((res: Servicos[]) => {
              this.servicos = res;
              console.log("services", res)
            });


          this.getCpo(nuga.dk);
          this.apiService.getServTec(ServiTec).subscribe((res: Servicos[]) => {
            this.servicos = res;
          });
        }
      });
    }


    if (this.formCadastro.get("FCtpInfo").value ==="DT" && this.OptecServ.length <= 0) {
      this.formCadastro.get("FCServTec").setValue('Elaboração de diretriz técnica')
      this.formCadastro.get("FCopTec").setValue('Não se aplica ao serviço técnico em questão');
      this.formCadastro.get("FCopTec").disabled
      //this.formCadastro.get("FCServTec").disabled


    }

  }
  logOut() {
    // console.log('cadastro.logout');
    this.apiService.logOut()

    this.router.navigate(["login"]);
  }

  public setCurrentLocation() {
    /*
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude, "city");

      });
    } else {
      this.setCurrentLocationLatLong();
    }
    */
  }
  formatString(value: string): string {
    if (value.length !== 21) {
      return value;
    }
    return `${value.substring(0, 2)}.${value.substring(2, 4)}.${value.substring(4, 8)}.${value.substring(8, 15)}.${value.substring(15, 19)}-${value.substring(19)}`;
  }

  setCurrentLocationLatLong() {
    let latitude = 0.0
    let longitude = 0.0
    let location;
    if (this.formCadastro.get("FcEndLatNum").value == "" && this.formCadastro.get("FcEndLongNum").value == "") {

      latitude = -22.9066254;
      longitude = -43.1756873;

      location = { latitude, longitude };
      this.onLocationSelected(location);
      this.endereco.tipo_end = "L"
      // this.address = "Av. Nilo Peçanha, 151 - Centro, Rio de Janeiro - RJ, 20020-100, Brasil";
    } else {
      latitude = this.formCadastro.get("FcEndLatNum").value;
      longitude = this.formCadastro.get("FcEndLongNum").value;
      location = { latitude, longitude };
      this.onLocationSelected(location);
      this.getAddress(latitude, longitude, "city")
      //console.log("GET ADRESS")
    }
    // this.getAddress(latitude,longitude);

    /*
      this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
            this.DadosEndGo = results[0].address_components
            // console.log(this.address)
          }
        }
      });
    */
  }

  markerDragEnd($event: MouseEvent) {
    // console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.endereco.tipo_end = "L"
    this.formCadastro.get("FcEndLatNum").setValue(this.latitude);
    this.formCadastro.get("FcEndLongNum").setValue(this.longitude);
    this.getAddress(this.latitude, this.longitude, "city");
    this.endereco.tipo_end = "L"

  }

  getAddress(latitude, longitude, tipo) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
            this.DadosEndGo = [];
            this.DadosEndGo = results[0].address_components;
            this.endereco.latitude = latitude;
            this.endereco.longitude = longitude;
            this.formCadastro.get("FcEndLatNum").setValue(latitude);
            this.formCadastro.get("FcEndLongNum").setValue(longitude);
            //this.DadosEndGo = results[0].address_components
            if (tipo === "city") {
              this.DadosEndGo = results[0].address_components;
              this.DadosEndGo.filter((res: any) => {
                if (res.types.indexOf("postal_code") > -1) {
                  //console.log('postal_code', res.long_name);
                  this.formCadastro.get("FcEndCep").setValue(res.long_name);
                  this.endereco.cep = res.long_name;
                }
                if (res.types.indexOf("administrative_area_level_2") > -1) {
                  // console.log('long_name', res.long_name);
                  this.formCadastro.get("FcEndCidade").setValue(res.long_name);
                  this.DataCity.filter((option) => {
                    if (option.Cidade.indexOf(res.long_name) > -1) {
                      this.endereco.municipio = option.id;
                    }
                  });
                }
                if (res.types.indexOf("street_number") > -1) {
                  // console.log('street_number', res.long_name);
                  this.formCadastro.get("FcEndNumero").setValue(res.long_name);
                  this.endereco.numero = res.long_name;
                }
                if (res.types.indexOf("sublocality") > -1) {
                  // console.log('sublocality', res.long_name);
                  this.formCadastro.get("FcEndBairro").setValue(res.long_name);
                  this.endereco.bairro = res.long_name;
                }

                if (res.types.indexOf("route") > -1) {
                  // console.log('route', res.long_name)
                  this.formCadastro
                    .get("FcEndLogradouro")
                    .setValue(res.long_name);
                  this.endereco.logradouro = res.long_name;
                }
                this.ChechEditEnd = true;
              });
            }

            // console.log('this',this.DadosEndGo)
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  changeValue(value, name) {
    if (name === "ckeckEnd") {
      this.checkLat = false;
      this.ckeckEnd = true;
      this.ChechEditEnd = false;
      this.setDisableEnd();
      this.setEnableCep();
      // this.formCadastro.get('FcEndLat').disable();
      // this.formCadastro.get('FcEndLong').enable();
      this.formCadastro.get("FcCidade").setValue("");
      this.formCadastro.get("FcEndLat").setValue("");
      this.formCadastro.get("FcEndCidade").setValue("");
      this.formCadastro.get("FcEndLogradouro").setValue("");
      this.formCadastro.get("FcEndBairro").setValue("");
      this.formCadastro.get("FcEndCep").setValue("");
      this.formCadastro.get("FcEndNumero").setValue("");
      this.formCadastro.get("FcEndComplemento").setValue("");
    }
    if (name === "checkLat") {
      const cep = this.formCadastro.get("FcEndCep").value;
      // console.log('cep', cep)
      if (this.formCadastro.get("FcEndCep").value !== "") {
        this.formCadastro.get("FcEndLat").setValue("");
        this.formCadastro.get("FcEndCidade").setValue("");
        this.formCadastro.get("FcEndLogradouro").setValue("");
        this.formCadastro.get("FcEndBairro").setValue("");
        this.formCadastro.get("FcEndCep").setValue("");
        this.formCadastro.get("FcEndNumero").setValue("");
        this.formCadastro.get("FcEndComplemento").setValue("");

      }
      this.checkLat = true;
      this.ChechEditEnd = false;
      this.formCadastro.controls.FcCidade.setValue("");
      this.setDisableEnd();
    }
    if (name === "checkDanoS") {
      this.checkDanoS = true;
    }
    if (name === "checkFcCompS") {
      if (this.checkFcCompS) {
        this.checkFcCompS = false;
      } else {
        this.checkFcCompS = true;
      }
      if (this.checkFcCompS && this.checkFcCompJp) {
        this.checkFcCompS = false;
        this.checkFcCompJp = false;
      }
    }
    if (name === "checkFcCompJp") {
      if (this.checkFcCompJp) {
        this.checkFcCompJp = false;
      } else {
        this.checkFcCompJp = true;
      }
    }
  }

  /* Filtros de Promotorias / Campos e Serviços */
  public _filter(value: string): Solicitante[] {
    const filterValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    let promo = "";

    this.promotorias.filter((option) => {
      if (
        option.nome
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
      ) {
        promo = option.id;
      }
    });
    this.form.solicitante = promo;
    return this.promotorias.filter(
      (option) =>
        option.nome
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
    );
  }

  public _filterITS(value: string): Lista[] {
    const filterValue = value.toUpperCase();
    return this.lista.filter(
      (option) =>
        option.itcn_NR_INFOTECNICA.toUpperCase().indexOf(filterValue) > -1
    );
  }
  private _filterSEI(value: string): Protoloco[] {
    //const filterValue = value.toLowerCase();
    return this.numSei.filter(
      (option) =>
        option.protocolo.indexOf(value) > -1
    );
  }

  public _filterCpo2(value: string): CPO1[] {
    const filterValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    return this.cpo2.filter(
      (option) =>
        option.ds
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
    );
  }
  public _filterSrvTec(value: string): Servicos[] {
    const filterValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    return this.servicos.filter(
      (option) =>
        option.ds
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
    );
  }
  public _filterCpo1(value: string): any[] {
    const filterValue = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
    return this.cpo1.filter(
      (option) =>
        option.ds
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toUpperCase()
          .indexOf(filterValue) > -1
    );
  }
  public _filterUnidades(value: string): any[] {
    const filterValue = value.toUpperCase();
    return this.DataSaude.filter(
      (option) => option.no_fantasia.toUpperCase().indexOf(filterValue) > -1
    );
  }
  public _filterTecnicos(value: string): any[] {
    const filterValue = value.toUpperCase();
    return this.tecnicos.filter(
      (option) => option.nome.toUpperCase().indexOf(filterValue) > -1
    );
  }

  public getCpo(nucleo) {
    //console.log("SELECT MULT", this.formCadastro.get("FCnucleo").value)
    console.log("NUGA 1", this.form.nucleo_gate)

    if(this.form.nucleo_gate === "" || this.form.nucleo_gate == undefined){
      this.form.nucleo_gate = nucleo[0];
    }
    console.log("NUGA 2", this.form.nucleo_gate)

    let ServiTec = this.formCadastro.get("FCnucleo").value;
    let Cpo1mult = this.formCadastro.get("FCnucleo").value;
    //let ServiTec = this.formCadastro.get("FCnucleo").value;
    this.apiService.getServTecMult(ServiTec)
      .subscribe((res: Servicos[]) => {
        this.servicos = res;
        //console.log("services", res)
      });
    let camps;
    if (this.NucleoTec === undefined || this.NucleoTec === '') {
      this.NucleoTec = nucleo;

    }


    console.log("NucleoTec 2", this.NucleoTec)
    if (this.apiService.Perfil === 'Administrador') {
      this.apiService.getCpo1Mult(Cpo1mult).subscribe((res: CPO1[]) => {
        this.cpo1 = res;
        // console.log("Cpos 1", res)
      });

    } else {
      this.apiService.getCpo1(this.NucleoTec).subscribe((res: CPO1[]) => {
        this.cpo1 = res;
        //console.log("Cpos 1", res)
      });
    }
    ServiTec.map((nucleo: any) => {
      if (nucleo === 6 || nucleo === 7 || nucleo === 3 || nucleo === 4) {
        this.CheckNuga = true;
      } else {
        this.CheckNuga = false;
      }
      if (nucleo === 2 || nucleo === 1) {
        this.CheckNugaSaude = true;
      }
    })

  }
  public getMotivo() {


    let TPRM = this.formCadastro.get("FCtpRengIt").value;
    if(this.formCadastro.get("FCtpRengIt").value != undefined && TPRM.length > 0 ){

      for(let i=0; i < TPRM.length; i++){

        this.form.inreng = "S";
        this.renegocia.push({id_reneg : TPRM[i]})


      }
    }else{
      this.form.inreng = "N";
      this.form.renegocia = [];

    }

        console.log('Renegocia', this.renegocia)
  }

  getCPFCNPJ(cpf) {
    const cnpjv = cpf.replace(/[^\d]+/g, "");
    // console.log('CNPJ > 11', cnpjv);
    const span = document.getElementById("cnpj");
    let terminate = false;

    this.formCadastro.get('FcNmInves').setValue('Pesquisando...');
    this.formCadastro.get('FcNmInves').setValue('')
    this.formCadastro.get('FcNmInves').enable();
    document.getElementById('nmcnpj').setAttribute('placeholder', "Digite aqui o nome do investigado encontrado na Receita")
    //document.getElementById('addCPF').setAttribute('[disabled]', 'false')
    if (cnpjv.length >= 11) {
      this.apiService.getCPF(cnpjv).subscribe((res: any[]) => {
        //console.log('CPF',res)
        res.map((res2: any) => {
          //console.log('CPF',res2)
          if (res2.valid === false && res2.nome === 'Não Encontrado na Base da Receita') {
            const dt1 = {
              titulo: "Erro CPF/CNPJ",
              status: 400,
              statusText:
                'CPF/CNPJ NÃO ENCONTRADO NA BASE DA RECEITA DO MP.' +
                '\n\n POR FAVOR VERIFIQUE O DADOS NO SITE DA RECITA FEDERAL' +
                '\n\n E OS INFORME MANUALMENTE '
            };

            this.msgdialogService.openDialog(dt1);
            this.formCadastro.get('FcNmInves').setValue('')
            this.formCadastro.get('FcNmInves').enable();
            terminate = true;

            document.getElementById('nmcnpj').setAttribute('placeholder', "Digite aqui o nome do investigado encontrado na Receita")
            document.getElementById('addCPF').setAttribute('[disabled]', 'false')
            return;
          }
          //CPF ou CNPJ inválido
          if (res2.valid === false && res2.nome === '') {
            const dt1 = {
              titulo: "Erro CPF/CNPJ",
              status: 400,
              statusText: cpf + ' ' + res2.nome + ' do MP. ' +
                '\n\n Por favor verifique o CNPJ no site da Receita Federal '
            };
            return false;
          }

          if (res2.valid === false && res2.nome === 'ConectError') {
            const dt1 = {
              titulo: "Erro CPF/CNPJ",
              status: 400,
              statusText:
                'CPF/CNPJ NÃO ESCONTRADO NA BASE DA RECEITA DO MP. POR FAVOR INFORME OS DADOS MANUALMENTE.'
            };

            this.msgdialogService.openDialog(dt1);
            this.formCadastro.get('FcNmInves').setValue('')
            this.formCadastro.get('FcNmInves').enable();
            terminate = true;

            document.getElementById('nmcnpj').setAttribute('placeholder', "Digite aqui o nome do investigado encontrado na Receita")
            document.getElementById('addCPF').setAttribute('[disabled]', 'false')
            return;
          } else {
            terminate = true;
            this.formCadastro.get("FcNmInves").setValue(res2.nome);
            // console.log('buscando nome', res2.nome);
            document
              .getElementById("addCPF")
              .setAttribute("[disabled]", "false");
            return res2.nome;
          }

        });
      });
    } else {
      this.formCadastro.get("FcNmInves").setValue("");
      return null;
    }
  }
  public getCpo2(e) {

    this.contrato = new RegExp(/^Contrat*/);

    if (this.contrato.test(e.option.value)) {
      this.checkContrato = true;
      document.getElementById("demo").setAttribute("class", "collapse show");
      this.form.nvl_proced = null;
    } else {
      document.getElementById("demo").setAttribute("class", "collapse");
      this.checkContrato = false;
      this.formCadastro.get("FcNvl_dano").setValidators(Validators.required);
    }
    const nugadk = this.form.nucleo_gate
    let cpo = "";
    this.cpo1.filter((res) => {
      if (res.ds.indexOf(e.option.value) > -1) {
        cpo = res.dk;
      }
    });
    if (cpo !== "") {
      this.selectCpo1 = true;
    }
    this.form.campo_1 = cpo;
    this.apiService.getCpo2(0, cpo).subscribe((res: CPO2[]) => {
      this.cpo2 = res;
    });
  }
  public getTecnico() {
    this.apiService.getTecnicos().subscribe((res: Tecnicos[]) => {
      this.tecnicos = res;
      //// // console.log('', res);
    });
  }
  public getServTec(e) {
    const nucleo = this.formCadastro.get("FCnucleo").value;
    let cpo2 = "";
    if (e.option.value === undefined) {
      // console.log('error value');
    }
  }
  getEndUnidade(value) {
    this.formCadastro.get("FcEndRadio").setValue("E");
    this.DataSaude.map((res: any) => {
      // console.log(value);
      if (res.no_fantasia === value) {
        this.latitude = res.latitude;
        this.longitude = res.longitude;
        this.endereco.tipo_end = "T";
        // console.log('Res', this.latitude, this.longitude);

        this.getAddress(this.latitude, this.longitude, "city");
        // console.log('DadosEndGo 1', this.DadosEndGo);
      }

      // this.getAddress(this.latitude, this.longitude);
    });

    /*
        this.formCadastro.get('FcEndNumero').setValue(res.Numero);

    */
  }
  displayWith(obj?: any): string | undefined {
    return obj ? obj.name : undefined;
  }
  getMPRJValidado(obj) {
    this.apiService.getValidaMPRJ(obj).subscribe((res: any) => {
      if (res.cod === 200) {
        this.validaMprj = true;
      }
      if (res.cod !== 200) {
        this.validaMprj = false;
      }
    });
  }
  getMPrjDupli(obj) {
    this.validaMPRJDupli = undefined;
    this.validaMprj = undefined;
    this.apiService.getMprjDupli(obj).subscribe((res: any) => {
      //console.log("getMPrjDupli", res);
      if (res.cod === 200) {
        this.validaMPRJDupli = true;
        this.validaMprj = true;
      }
    });
    //console.log("getMPrjDupli", this.validaMPRJDupli);
    if (this.validaMPRJDupli !== true && this.validaMPRJDupli === undefined) {
      this.validaMPRJDupli = false;
    }
  }
  /**
   * Desabilita/limpa campos do cadastro de endereço
   */
  setDisableEnd() {
    if (!this.ChechEditEnd) {
      this.ckeckEnd = true;
      this.formCadastro.get("FcEndLat").setValue("");
      this.formCadastro.get("FcEndLat").disable();
      this.formCadastro.get("FcEndCidade").setValue("");
      this.formCadastro.get("FcEndCidade").disable();
      this.formCadastro.get("FcEndLogradouro").setValue("");
      this.formCadastro.get("FcEndLogradouro").disable();
      this.formCadastro.get("FcEndBairro").setValue("");
      this.formCadastro.get("FcEndBairro").disable();
      this.formCadastro.get("FcEndNumero").setValue("");
      this.formCadastro.get("FcEndNumero").disable();
      this.formCadastro.get("FcEndComplemento").setValue("");
      this.formCadastro.get("FcEndComplemento").disable();
      // FcEndComplemento
    }
  }

  setEnableCep() {
    if (!this.ChechEditEnd) {
      this.formCadastro.get("FcEndCep").enable();
    }
  }

  getEndCity(id) {
    this.DataCity.map((res: any) => {
      if (res.id === id) {
        // console.log(res);
        this.formCadastro.get("FcEndCidade").setValue(res.Cidade.toUpperCase());
        this.formCadastro
          .get("FcEndLogradouro")
          .setValue(res.Logradouro.toUpperCase());
        this.formCadastro.get("FcEndBairro").setValue(res.Bairro.toUpperCase());
        this.formCadastro.get("FcEndCep").setValue(res.CEP.toUpperCase());
        this.formCadastro.get("FcEndNumero").setValue(res.Numero);

        this.endereco.logradouro = res.Logradouro.toUpperCase();
        this.endereco.numero = res.Numero;
        this.endereco.complemento = "";
        this.endereco.bairro = res.Bairro.toUpperCase();
        this.endereco.municipio = res.id;
        this.endereco.cep = res.CEP.toUpperCase();
        this.endereco.tipo_end = "T";
        this.ChechEditEnd = false;
        this.ckeckEnd = true;
        this.formCadastro.get("FcEndRadio").setValue("E");
      }
      if (id === undefined) {
        this.changeValue(this.ckeckEnd, "ckeckEnd");
      }
    });

    //this.setDisableEnd()
  }
  Onpaste(e) {
    // console.log('paste', e)
  }
  ValidateContratos() { }


  public getCEP(cep) {
    // console.log(cep);
    cep = cep.replace(/\D/g, "");
    if (cep !== 123 && cep !== "") {
      if (cep < 20000000 || cep > 29999999) {
        const dt1 = {
          titulo: "CEP INVÁLIDO",
          status: 441,
          statusText: "CEP inválido ou fora do Estado do Rio de Janeiro.",
        };
        this.msgdialogService.openDialog(dt1);
        this.formCadastro.get("FcEndLat").setValue("");
        this.formCadastro.get("FcEndLat").disable();
        this.formCadastro.get("FcEndCidade").setValue("");
        this.formCadastro.get("FcEndCidade").disable();
        this.formCadastro.get("FcEndLogradouro").setValue("");
        this.formCadastro.get("FcEndLogradouro").disable();
        this.formCadastro.get("FcEndBairro").setValue("");
        this.formCadastro.get("FcEndBairro").disable();
        this.formCadastro.get("FcEndNumero").setValue("");
        this.formCadastro.get("FcEndNumero").disable();
        this.formCadastro.get("FcEndComplemento").setValue("");
        this.formCadastro.get("FcEndComplemento").disable();
        return false;
      }
      this.formCadastro.get("FcEndRadio").setValue("E");
      this.apiService.getCep(cep).subscribe((res: any) => {
        if (res.localidade === "" || res.localidade === undefined) {
          const dt1 = {
            titulo: "CEP INVÁLIDO",
            status: 'Alerta',

            statusText: "O CEP informado não pode ser encontrado na \r\n base de dados dos correios. " +
              "pesquise em: \r\n ",
            statusLink: "https://buscacepinter.correios.com.br/app/endereco/index.php",

          };
          this.msgdialogService.openDialog(dt1);
        }
        const logr = res.logradouro;

        this.formCadastro.get("FcCidade").setValue("");
        this.formCadastro.get("FcCidade").enable();
        this.formCadastro.get("FcEndLat").setValue("");
        this.formCadastro.get("FcEndLat").enable();
        this.formCadastro.get("FcEndCidade").setValue("");
        this.formCadastro.get("FcEndCidade").enable();
        this.formCadastro.get("FcEndLogradouro").setValue("");
        this.formCadastro.get("FcEndLogradouro").enable();
        this.formCadastro.get("FcEndBairro").setValue("");
        this.formCadastro.get("FcEndBairro").enable();
        this.formCadastro.get("FcEndNumero").setValue("");
        this.formCadastro.get("FcEndNumero").enable();
        this.formCadastro.get("FcEndComplemento").setValue("");
        this.formCadastro.get("FcEndComplemento").enable();
        //console.log("cidade", res.localidade)

        if (res.localidade !== "" || res.localidade !== undefined) {
          this.formCadastro
            .get("FcEndCidade")
            .setValue(res.localidade);
          this.formCadastro.get("FcEndCidade").disable();
        }
        if (res.logradouro !== "") {
          this.formCadastro
            .get("FcEndLogradouro")
            .setValue(res.logradouro);
          this.formCadastro.get("FcEndLogradouro").disable();
        }
        if (res.bairro !== "") {
          this.formCadastro
            .get("FcEndBairro")
            .setValue(res.bairro);
          this.formCadastro.get("FcEndBairro").disable();
        }
        this.formCadastro.get("FcEndCep").setValue(res.cep);
        let cidadk;
        this.DataCity.filter((option) => {
          if (option.Cidade.indexOf(res.localidade) > -1) {
            cidadk = option.id;

          }
        });
        if (cep === "22231901") {
          this.formCadastro.get("FcEndNumero").setValue("S/N");
          this.formCadastro.get("FcEndNumero").disable();
        }
        this.endereco.logradouro = res.logradouro;
        this.endereco.numero = "";
        this.endereco.complemento = "";
        this.endereco.bairro = res.bairro;
        this.endereco.municipio = cidadk;
        this.endereco.cep = res.cep;
        this.endereco.tipo_end = "E"
      });
      this.ChechEditEnd = true;
      this.setDisableEnd();
    } else {
      this.ChechEditEnd = false;
      this.setDisableEnd();
      // this.formCadastro.get('FcEndLat').disable();
      // this.formCadastro.get('FcEndLong').enable();
      this.formCadastro.get("FcCidade").setValue("");
      this.formCadastro.get("FcEndLat").setValue("");
      this.formCadastro.get("FcEndLat").disable();
      this.formCadastro.get("FcEndCidade").setValue("");
      this.formCadastro.get("FcEndCidade").disable();
      this.formCadastro.get("FcEndLogradouro").setValue("");
      this.formCadastro.get("FcEndLogradouro").disable();
      this.formCadastro.get("FcEndBairro").setValue("");
      this.formCadastro.get("FcEndBairro").disable();
      this.formCadastro.get("FcEndCep").setValue("");
      this.formCadastro.get("FcEndNumero").setValue("");
      this.formCadastro.get("FcEndNumero").disable();
      this.formCadastro.get("FcEndComplemento").setValue("");
      this.formCadastro.get("FcEndComplemento").disable(); //this.ChechEditEnd = true;
      //this.ChechEditEnd = true;
      //console.log(this.endereco);
      this.setDisableEnd();
    }
    if (this.endereco.municipio === "") {
      // console.log("DEU RUIM")
    }

  }
  public validateCpo1(e, valor) {
    this.selectCpo1 = null;
    let cpo1 = this.formCadastro.get("FCcpo1").value; // = valor;
    this.formCadastro.get("FCcpo2").setValue("");
    cpo1 = cpo1.trim();
    let validaCpo1 = "";
    if (cpo1.length > 0) {
      if (valor === "select" || "change") {
        this.cpo1.map((res) => {
          if (res.ds === cpo1) {
            validaCpo1 = res.ds.toUpperCase();
            this.selectCpo1 = true;
            this.form.campo_1 = res.dk;
            return;
          }
        });
        if (this.selectCpo1 !== true) {
          this.selectCpo1 = false;
        }
      }
    }
  }

  public validateCpo2(e, valor) {
    this.selectCpo2 = null;
    let cpo = this.formCadastro.get("FCcpo2").value; // = valor;
    cpo = cpo.trim();
    // console.log('antes if', cpo)
    if (cpo.length > 0) {
      if (valor === "select" || "change") {
        this.cpo2.map((res) => {
          if (res.ds === cpo) {
            this.form.campo_2 = res.dk = res.dk;
            this.selectCpo2 = true;
          }
        });
      }
      if (this.selectCpo2 !== true) {
        this.selectCpo2 = false;
      }
    }
  }

  public validateServTec(e, valor) {
    this.selectSrv = null;
    let srv = this.formCadastro.get("FCServTec").value; // = valor;
    srv = srv.trim();
    if (srv.length > 0) {
      if (valor === "select" || "change") {
        this.servicos.map((res) => {
          if (res.ds === srv) {
            this.selectSrv = true;
          }
        });
      }
      if (this.selectSrv !== true) {
        this.selectSrv = false;
      }
    }
  }
  selectionMade(event: Event) {
    event.stopPropagation();
    this.trigger.openPanel();
  }
  // Valida se a promotiroa preenchida é valida
  public validateSolicitante(e, valor) {
    this.selectSoli = null;
    let promo = this.formCadastro.get("Fcsolicitante").value; // = valor;
    promo = promo.trim();
    if (promo.length > 0) {
      if (valor === "select" || "change") {
        this.promotorias.map((res) => {
          if (res.nome === promo) {
            this.selectSoli = true;
            this.form.solicitante = res.id;
            return;
          }
        });
      }
      if (this.selectSoli !== true) {
        this.selectSoli = false;
      }
    }
  }

  public getSeiPromo(numSei) {
    console.log('numSei', numSei);
    numSei = this.formatString(numSei)
    console.log("NumSei", numSei)
    this.formCadastro.get("FCnumeroSei").setValue(numSei)
    this.setUserLogin();
    this.getMotivo();
    this.apiService.getSolict(numSei).subscribe((res: any) => {
      // console.log('NumMprj', res);
      const nome = res.nome;
      const mprj = res.NumMprj;
      // console.log('NumMprj', res.NumMprj);

      if (res.resultMPRJ !== "Ok") {
        this.formCadastro.get("FCmprj").setValue("");
        this.validaMprj = false;
      }

      const verifyMprj = res.resultMPRJ;
      const mrpjFormat = mprj.replace(/(\d{4})(\d)/, "$1.$2");
      // console.log('mrpjFormat', mrpjFormat);
      this.formCadastro.get("Fcsolicitante").setValue(nome); // = valor;
      this.form.solicitante = res.id;
      if (verifyMprj === "Ok") {
        this.formCadastro.get("FCmprj").setValue(mrpjFormat);
        this.form.numero_mprj = mprj;
        this.validaMprj = true;
        // this.getMPRJValidado(mprj);
        this.getMPrjDupli(mprj);
      }
      this.setNuga();
    });
  }
  mprj_format(mprj) {
    return mprj.replace(/(\d{4})(\d)/, "$1.$2");
  }
  isAllSelected(obj) {
    const numSelected = this.selection.selected.length;
    let numRows = 0;
    if (obj === "SERV") {
      numRows = this.dataSource.data.length;
    }
    if (obj === "TEC") {
      numRows = this.dataSourceTecnico.data.length;
    }
    if (obj === "CPF") {
      numRows = this.dataSourceCPF.data.length;
    }
    return numSelected === numRows;
  }
  masterToggle(obj) {
    if (obj === "SERV") {
      this.isAllSelected(obj)
        ? this.selection.clear()
        : this.dataSource.data.forEach((row) => this.selection.select(row));
    }
    if (obj === "TEC") {
      this.isAllSelected(obj)
        ? this.selection.clear()
        : this.dataSourceTecnico.data.forEach((row) =>
          this.selectionTecnico.select(row)
        );
    }
    if (obj === "CPF") {
      this.isAllSelected(obj)
        ? this.selection.clear()
        : this.dataSourceCPF.data.forEach((row) =>
          this.selectionCPF.select(row)
        );
    }
  }

  removeSelectedRows(evento: string) {
    if (evento === "ServTec") {
      this.selection.selected.forEach((item) => {
        const index: number = this.dataTable.findIndex((d) => d === item);
        this.dataTable.splice(index, 1);
        this.servitec = [];
        this.OptecServ = [];
        if (item.opiniao === "Não se aplica ao serviço técnico em questão") {
          this.formCadastro.get("FcCompTec").setValue(" ");
          this.CompOp = false;
        }

      });
      this.dataTable.forEach((item) => {
        let opt;
        let srv;
        this.servicos.filter((option) => {
          if (option.ds.indexOf(item.servico) > -1) {
            this.servitec.push({ id: option.dk });
            srv = option.dk;
          }
        });
        this.optec.filter((option) => {
          if (option.ds.indexOf(item.opiniao) > -1) {
            this.OptecServ.push({ id_servico: srv, id_opiniao: option.dk });
          }
        });
      });
      this.dataSource = new MatTableDataSource<Element>(this.dataTable);
      this.selection = new SelectionModel<Element>(true, []);
    }
    console.log("this.cpfs_cnpjs antes", this.cpfs_cnpjs)

    if (evento === "CNPJ") {
      this.selectionCPF.selected.forEach((item) => {
        const cpf = item.cpf_cnpj.replace(/\D/g, "");
        const index: number = this.dataTableCPF.findIndex((d) => d === item);
        this.dataTableCPF.splice(index, 1);

        const index2: number = this.cpfs_cnpjs.findIndex((d) => d.cpf_cnpj === cpf);
        this.cpfs_cnpjs.splice(index2, 1);

      });
      console.log("this.cpfs_cnpjs depois", this.cpfs_cnpjs)
      let val = 0;

      this.dataTableCPF.map((d) => {
        val = val + d.val_dano;
      });

      this.totDano = val;

      if (this.totDano > 0) {
        // console.log('val if', val);
        this.checkDanoS = true;
      } else if (this.totDano <= 0) {
        this.checkDanoS = false;
      }

      this.formCadastro.get("FcDano").setValue(this.totDano);
      this.dataSourceCPF = new MatTableDataSource<Element2>(this.dataTableCPF);
      this.selectionCPF = new SelectionModel<Element2>(true, []);
    }
    if (evento === "Tecnico") {
      this.selectionTecnico.selected.forEach((item) => {
        const index: number = this.dataTableTecnicos.findIndex(
          (d) => d === item
        );
        // console.log('TESTE 1', item.tecnico);

        if (item.tecnico === this.usuario) {
          return;
        }

        this.dataTableTecnicos.splice(index, 1);
        this.dadosTec.slice(index, 1);
      });
      // console.log('Tec', this.tecnico)
      this.tecnico = [];
      this.dataTableTecnicos.forEach((item) => {
        this.tecnicos.filter((option) => {
          if (option.nome.indexOf(item.tecnico) > -1) {
            this.tecnico.push({ id: option.matric });
          }
        });
      });
      // console.log('dataTableTecnicos', this.dataTableTecnicos.length )
      this.dataSourceTecnico = new MatTableDataSource<ElementTec>(
        this.dataTableTecnicos
      );
      this.selectionTecnico = new SelectionModel<ElementTec>(true, []);
      // console.log('Remove')
    }
  }
  addRows(servico, opniao) {
    const Servico = this.formCadastro.get("FCServTec").value;
    const Opniao = this.formCadastro.get("FCopTec").value;
    const Tecnico = this.formCadastro.get("FcTec").value;
    let valid = true;
    let op;
    let serv;
    let tec


    if (Servico === "" || Opniao === "" || Tecnico === "") {
      const dt1 = {
        titulo: "Erro serviço técnico",
        status: 439,
        statusText:
          "É obrigatorio selecionar um serviço técnico e uma opnião técnica.",
      };
      this.msgdialogService.openDialog(dt1);
      return false;
    }
    if (this.selectSrv === false) {
      const dt1 = {
        titulo: "Erro serviço técnico",
        status: 440,
        statusText:
          "O serviço técnico selecionado não foi encontrado na base do ProData.",
      };
      this.msgdialogService.openDialog(dt1);
      return false;
    }

    this.optec.filter((option) => {
      if (option.ds.indexOf(Opniao) > -1) {
        op = option.dk;
      }
    });
    this.tecnicos.filter((option) => {
      if (option.nome.indexOf(Tecnico) > -1) {
        tec = option.matric;
      }
    })
    if (op === 5) {
      this.CompOp = true;
    }

    this.servicos.filter((option) => {
      if (option.ds.indexOf(Servico) > -1) {
        serv = option.dk;
      }
    });
    if (this.OptecServ.length > 0) {
      this.OptecServ.map((opsrv) => {
        if (opsrv.id_servico === serv && opsrv.cdmatricula === tec) {
          valid = false;
          const dt1 = {
            titulo: " Serviço já Cadastrado",
            status: 223,
            statusText:
              "O Serviço Técnico: " + Servico + " já  encontra-se na lista do Técnico " + Tecnico,
          };
          this.msgdialogService.openDialog(dt1);

          return;
        }
      });
    }
    if (valid && this.itcn_dt != 'true') {
      console.log('serviço', serv)
      this.OptecServ.push({ id_servico: serv, id_opiniao: op, cdmatricula: tec });
      let intserv = 0
      if (this.servitec.length > 0) {
        this.servitec.filter((servmap) => {
          if (serv == servmap.id) {
            intserv = serv + 1
          }
          // console.log('serviço igual', serv)
        })
      } else {
        this.servitec.push({ id: serv });
        intserv = 1
      }
      if (intserv == 0) {
        // console.log('Cadastrou Serviço ', serv)
        this.servitec.push({ id: serv });
      }
      //console.log('Serviços',this.servitec)

      let int = 0;
      if (this.tecnico.length > 0) {
        this.tecnico.filter((option) => {
          if (option.id == tec) {
            int = option.id + 1;
          }
          console.log('Cadastro ', this.tecnico)
        })
      } else {
        this.tecnico.push({ id: tec });
        int = 1;
      }
      if (int == 0) {
        console.log('Cadastrou', tec)
        this.tecnico.push({ id: tec });
      }
      console.log('Técnicos', this.tecnico)
      /*

      */

      console.log('Serviços', this.servitec)
      this.form.opiniao_tecnica = this.OptecServ;
      console.log('Opiniao', this.form.opiniao_tecnica)

      this.dados.push({
        position: this.dataTable.length + 1,
        servico: Servico,
        opiniao: Opniao,
        tecnico: Tecnico,
      });
      this.dataTable = this.dados;
      this.dataSource = new MatTableDataSource<Element>(this.dataTable);
    }

    if (this.itcn_dt == 'true') {
      if (serv != 400) {
        const dt1 = {
          titulo: " Serviço inválido",
          status: 223,
          statusText:
            "Na Diretriz tecnica só é possivel cadastrar (Elaboração de diretriz técnica) ",
        };
        this.msgdialogService.openDialog(dt1);

      }else{

        this.OptecServ.push({ id_servico: 400, id_opiniao: 5, cdmatricula: tec });
        this.servitec.push({ id: 400 });
        this.tecnico.push({id:tec})
      }

      console.log(this.form)
      console.log(this.tecnico)
      this.dados.push({
        position: this.dataTable.length + 1,
        servico: Servico,
        opiniao: Opniao,
        tecnico: Tecnico,
      });
      this.dataTable = this.dados;
      this.dataSource = new MatTableDataSource<Element>(this.dataTable);

    }
    //console.log("DT1" ,this.itcn_dt)
    if (this.itcn_dt == 'true') {
      //console.log("DT2", this.itcn_dt)
      this.formCadastro.get("FCServTec").setValue('Elaboração de diretriz técnica')
      this.formCadastro.get("FCopTec").setValue('Não se aplica ao serviço técnico em questão');
      this.formCadastro.get("FcTec").setValue('')
      this.formCadastro.get("FCopTec").disabled
      this.formCadastro.get("FCServTec").disabled

    } else {
      this.formCadastro.get("FCServTec").setValue("");
      this.formCadastro.get("FCopTec").setValue("");
      this.formCadastro.get("FcTec").setValue("");
    }
  }

 removerDuplicadosComplexos(arr: any[]): any[] {
  const vistos: { [key: string]: boolean } = {}; // Objeto para rastrear itens já vistos
  const unicos: any[] = [];

  for (let item of arr) {
      // Converte o item em string para usar como chave
      const chave = JSON.stringify(item);

      // Se o item ainda não foi visto, adiciona ao array de únicos
      if (!vistos[chave]) {
          vistos[chave] = true;
          unicos.push(item);
      }
  }

  return unicos;
}

// Exemplo de uso:
//const arrayComplexo = [1, 2, 3, 4, { a: 1 }, { a: 1 }, 3, 2, 1];
//console.log(removerDuplicadosComplexos(arrayComplexo));

// Exemplo de uso:
//const arraySimples = [1, 2, 3, 4, 3, 2, 1];
//console.log(removerDuplicadosSimples(arraySimples));


  AddRowsCPF() {
    const cnpj = this.formCadastro.get("FcCnpj").value;
    const nome = this.formCadastro.get("FcNmInves").value;
    let totContratosAna = this.formCadastro.get("FctotContra").value;
    let totContratosCd = this.formCadastro.get("FctotContraCd").value;
    let valorDanoContra = this.formCadastro.get("FcValDano").value;
    let valid = true;
    if (this.checkContrato === true) {
      if (
        totContratosAna === "" ||
        totContratosCd === "" ||
        totContratosAna === 0
      ) {
        valid = false;
        const dt1 = {
          titulo: "Nº de contratos",
          status: 338,
          statusText: "É necessário inserir o total de contratos analisados.",
        };
        this.msgdialogService.openDialog(dt1);
        return;
      }
      if (totContratosCd > totContratosAna) {
        valid = false;
        const dt1 = {
          titulo: "Nº de contratos",
          status: 339,
          statusText:
            "O total de contratos com dano não pode ser maior que o total de contratos analisados.",
        };
        this.msgdialogService.openDialog(dt1);
        return;
      }
      if (
        totContratosCd > 0 &&
        (valorDanoContra === 0 || valorDanoContra === "")
      ) {
        valid = false;
        const dt1 = {
          titulo: "Nº de contratos",
          status: 340,
          statusText:
            "Por existir contratos com dano o valor do dano não pode ser zerado.",
        };
        this.msgdialogService.openDialog(dt1);
        return;
      }
    }
    if (totContratosAna === "" || totContratosAna <= 0) {
      this.formCadastro.get("FctotContra").setValue(0);
      totContratosAna = 0;
    }
    if (totContratosCd === "" || totContratosCd <= 0) {
      this.formCadastro.get("FctotContraCd").setValue(0);
      totContratosCd = 0;
    }
    if (valorDanoContra === "" || valorDanoContra <= 0) {
      this.formCadastro.get("FcValDano").setValue(0);
      valorDanoContra = 0;
    }
    if (cnpj === "" || nome === "" || nome === "Pesquisando...") {
      valid = false;
      const dt1 = {
        titulo: "Erro CNPJ",
        status: 341,
        statusText:
          "Aguarde a pesquisa pelo nome. \r\n É obrigatorio inserir o CPF/CNPJ e nome do investigado.",
      };




      this.msgdialogService.openDialog(dt1);
      return;
    }

    if (this.dadosCPF.length > 0) {
      this.dadosCPF.filter((res) => {
        if (res.cpf_cnpj === cnpj) {
          valid = false;
          const dt1 = {
            titulo: " CPF/CNPJ já Cadastrado",
            status: 323,
            statusText: "O CNPJ " + cnpj + " já  encontra-se na lista.",
          };
          this.msgdialogService.openDialog(dt1);
          return;
        }
      });
    }
    /*
      if (this.tecnico.length > 0) {
        this.tecnico.filter((matr) => {
          if (matr.id === matricula) {
            valid = false;
            const dt1 = {
              titulo: " Técnico já Cadastrado",
              status: 223,
              statusText: "O Técnico " + nome + " já  encontra-se na lista",
            };
            this.msgdialogService.openDialog(dt1);
            return;
          }
        });
    */
    if (valid) {
      this.dadosCPF.push({
        position: this.dataTableCPF.length + 1,
        cpf_cnpj: cnpj,
        nm_contratada: nome,
        num_contratos: totContratosAna,
        num_contratos_dano: totContratosCd,
        val_dano: valorDanoContra,
      });
      this.dataTableCPF = this.dadosCPF;
      this.cpfs_cnpjs.push({
        cpf_cnpj: cnpj.replace(/\D/g, ""),
        nm_contratada: nome,
        num_contratos: totContratosAna,
        num_contratos_dano: totContratosCd,
        val_dano: valorDanoContra,
      });
      this.dataSourceCPF = new MatTableDataSource<Element2>(this.dataTableCPF);
      if (valorDanoContra > 0) {
        this.totDano = this.totDano + valorDanoContra;
        this.setDanos("Dano");
        this.checkDanoS = true;
        this.checkDanoAuto = true;
        this.formCadastro.get("FcCheckDano");
        this.formCadastro.get("FcCheckDano").disable();
        this.formCadastro.get("FcDano").disable();
        this.form.dano_erario = "S";
      } else if (this.totDano <= 0) {
        this.setDanos("Dano");
        this.checkDanoS = false;
        this.checkDanoAuto = false;
        this.formCadastro.get("FcCheckDano");
        this.formCadastro.get("FcCheckDano").disable();
        this.formCadastro.get("FcDano").disable();
        this.form.dano_erario = "N";
      }
      const valorDanoContraCurrency = formatCurrency(
        valorDanoContra,
        "pt_BR",
        "R$",
        "BRL"
      );
      const totDanoCurrency = formatCurrency(
        this.totDano,
        "pt_BR",
        "R$",
        "BRL"
      );
      // console.log(valorDanoContraCurrency)
      if (valorDanoContra > 0) {
        // const valorDanoContraCurrency = formatCurrency(valorDanoContra,'pt_BR', 'R$', 'BRL','2')
        const dt1 = {
          titulo: "Confirme o valor do dano:",
          status: "Aviso",
          statusText:
            "Valor do dano é " +
            valorDanoContraCurrency +
            "\r\n" +
            " e total de dano ao erário é " +
            totDanoCurrency,
        };
        this.msgdialogService.openDialog(dt1);
      }
    }
    this.formCadastro.get("FcDano").setValue(this.totDano);
    this.formCadastro.get("FcCnpj").setValue("");
    this.formCadastro.get("FcNmInves").setValue("");
    this.formCadastro.get("FctotContra").setValue("");
    this.formCadastro.get("FctotContraCd").setValue("");
    this.formCadastro.get("FcValDano").setValue("");
    this.form.valor_dano = this.formCadastro.get("FcDano").value;
  }

  AddRowsTec(odj) {
    let nome = "";
    let matricula;

    if (odj === "Auto") {
      nome = this.usuario;
      this.tecnicos.filter((option) => {
        if (option.nome.indexOf(nome) > -1) {
          matricula = option.matric;
        }
      });
      //this.tecnico.push({ id: matricula });
      this.dadosTec.push({
        position: this.dataTableTecnicos.length + 1,
        tecnico: nome,
      });
      //this.formCadastro.get("FcTec")
      this.dataTableTecnicos = this.dadosTec;
      this.dataSourceTecnico = new MatTableDataSource<ElementTec>(
        this.dataTableTecnicos
      );
      // this.formCadastro.get('FcTec').setValue('');
    }
    nome = this.formCadastro.get("FcTec").value;

    if (nome === "" && odj !== "Auto") {
      const dt1 = {
        titulo: "Erro Técnico",
        status: 439,
        statusText: "Selecione um Técnico",
      };
      this.msgdialogService.openDialog(dt1);
      return false;
    }
    matricula = "";

    let valid = true;

    if (nome !== "" && odj === "get") {
      this.tecnicos.filter((option) => {
        if (option.nome.indexOf(nome) > -1) {
          matricula = option.matric;
        }
      });
      if (this.tecnico.length > 0) {
        this.tecnico.filter((matr) => {
          if (matr.id === matricula) {
            valid = false;
            const dt1 = {
              titulo: " Técnico já Cadastrado",
              status: 223,
              statusText: "O Técnico " + nome + " já  encontra-se na lista",
            };
            this.msgdialogService.openDialog(dt1);
            return;
          }
        });
      }
      if (valid) {
        this.tecnico.push({ id: matricula });
        this.dadosTec.push({
          position: this.dataTableTecnicos.length,
          tecnico: nome,
        });

        this.dataTableTecnicos = this.dadosTec;
        this.dataSourceTecnico = new MatTableDataSource<ElementTec>(
          this.dataTableTecnicos
        );
        this.formCadastro.get("FcTec").setValue("");
      }
    }
  }

  helpOpen(e) {
    let id = "@SEI";
    this.httpClient.get("assets/mocks/help.json").subscribe((data: any[]) => {
      data.map((res) => {
        if (res.id === id) {
          this.helpservice.openDialog(res);
        }
      });
    });

    // this.helpservice.openDialog(dt1);
  }

  setProgess(e, valor) {
    // console.log('Valor', valor);
    // console.log('Stepper', this.stepper);

    let steperPrev = false;
    this.contrato = new RegExp(/^Contrat*/);

    const frist10 = this.formCadastro.get("FCnumeroSei").value; //nrsei
    const frist11 = this.formCadastro.get("FCmprj").value;
    const frist12 = this.formCadastro.get("Fcsolicitante").value;
    const frist13 = this.formCadastro.get("FCnucleo").value;
    const step21 = this.formCadastro.get("FCcpo1").value;
    const step22 = this.formCadastro.get("FCcpo2").value;
    const step23 = this.dataSourceTecnico.data.length;
    const step24 = this.dataSource.data.length;
    const step25 = this.formCadastro.get("FCtpInfo").value; //tpInfo
    const step26 = this.formCadastro.get("FcCompTec").value; //Complemento Op.
    const step31 = this.formCadastro.get("FcEndCep").value;
    const step32 = this.formCadastro.get("FcEndLatNum").value;
    const step33 = this.formCadastro.get("FcEndLongNum").value;


    // VOLTAR AO PRIMEIRO PASSO
    if (valor === 29) {
      this.stepper.selectedIndex = 0;
    }


    // VALIDAR E AVANÇAR AO SEGUNDO PASSO
    if (valor === 30) {
      // this.validateSolicitante(this.stepper, 'blur')
      if (
        frist10 === "" ||
        frist11 === "" ||
        frist12 === "" ||

        this.selectSoli === false ||
        this.validaMprj === false
      ) {
        // console.log(frist11, frist12, frist13);
        const dt1 = {
          titulo: "OPS",
          status: 102,
          statusText:
            "Alguns dos campos não foram peenchidos ou não estão corretos! Por favor verifique os campos em vermelho.",
        };
        this.msgdialogService.openDialog(dt1);
        valor = 0;
        steperPrev = true;
        this.stepper.selectedIndex = 0;
        this.stepper.selected.completed = false;
        return;
      } else {
        this.stepper.selected.completed = true;
        this.stepper.selectedIndex = 1;
        this.stepper.selected.completed = false;
      }
    }

    //VOLTAR AO SEGUNDO PASSO

    if (valor === 59) {
      this.stepper.selectedIndex = 1;
    }

    //VALIDAR E AVANÇAR AO TERCEIRO PASSO
    if (valor === 60) {
      if (
        step21 === "" ||
        step22 === "" ||
        frist13 === "" ||
        step24 <= 0 ||
        this.selectCpo1 === false ||
        this.selectCpo2 === false
      ) {
        // console.log(step21, step22, step23);
        const dt1 = {
          titulo: "OPS",
          status: 201,
          statusText:
            "Alguns dos campos não foram preenchidos ou não estão corretos! Por favor verifique os campos em vermelho.",
        };
        this.msgdialogService.openDialog(dt1);
        valor = 30;
        this.stepper.selectedIndex = 1;
        this.stepper.selected.completed = false;
        return;
      } else {
        this.stepper.selected.completed = true;
        this.stepper.selectedIndex = 2;
        this.stepper.selected.completed = false;
      }
      if(step25 === "DT" && step26 === ""){
        const dt1 = {
          titulo: "OPS",
          status: 201,
          statusText:
            "Para Diretriz Técnica é necessario preencher o campo 'COMPLEMENTO DA OPINIÃO TÉCNICA'  ",
        };
        this.msgdialogService.openDialog(dt1);
        this.msgdialogService.openDialog(dt1);
        valor = 30;
        this.stepper.selectedIndex = 1;
        this.stepper.selected.completed = false;
      }
    }

    //VOLTAR AO TERCEIRO PASSO
    if (valor === 89) {
      this.stepper.selectedIndex = 1;
    }

    //VALIDAR E AVANÇAR AO QUARTO PASSO
    if (valor === 90) {
      ///
      if (
        this.form.dano_erario === "S" &&
        this.formCadastro.controls.FcDano.value === ""
      ) {
        const dt3 = {
          titulo: "OPS",
          status: "Dano",
          statusText: "O dano esta marcado como Sim mais seu valor e 0,00",
        };
        this.msgdialogService.openDialog(dt3);
        valor = 60;
        this.stepper.selected.completed = false;
        this.stepper.selectedIndex = 2;
        return;
      }
      if (step31 === '' && step32 === '') {

        const dt3 = {
          titulo: "OPS",
          status: "Endereço",
          statusText:
            "É obrigatório o preenchimento de um endereço ou Lat/Long.",
        };
        this.msgdialogService.openDialog(dt3);
        valor = 60;
        this.stepper.selected.completed = false;
        this.stepper.selectedIndex = 2;
        return;
      } else {

        this.stepper.selected.completed = true;
        this.stepper.selectedIndex = 3;
        this.stepper.selected.completed = false;
      }

      if (this.contrato.test(step21)) {
        if (this.formCadastro.get("FcNvl_dano").value === "") {
          const dt1 = {
            titulo: "OPS",
            status: 443,
            statusText:
              "Para o campo 1 igual a contratos é obrigatório o preenchimento do nivel do dano (municipal ou estadual).",
          };
          this.msgdialogService.openDialog(dt1);
          valor = 60;
          this.stepper.selected.completed = false;
          this.stepper.selectedIndex = 2;
          return;
        }
        if (this.formCadastro.get("FcTpContrato").value === "") {
          const dt1 = {
            titulo: "OPS",
            status: 443,
            statusText:
              "Para o campo 1 igual a contratos é obrigatório o preenchimento objeto de contrato (insumo, obra ou serviço).",
          };
          steperPrev = true;
          this.msgdialogService.openDialog(dt1);
          valor = 60;
          this.stepper.selectedIndex = 2;
          this.stepper.selected.completed = false;
        }
        if (this.dataSourceCPF.data.length <= 0) {
          const dt1 = {
            titulo: "OPS",
            status: 443,
            statusText:
              "Para o Campo 1 igual a contratos é obrigatorio o preenchimento de uma empresa.",
          };
          steperPrev = true;
          this.msgdialogService.openDialog(dt1);
          valor = 60;
          this.stepper.selectedIndex = 2;
          this.stepper.selected.completed = false;
          return;
        }
      }
    }
    this.progress = valor;
  }

  NumMPRJ(v) {
    v = this.formCadastro.get("FCmprj").value;
    v = v.replace(/\D/g, "");
    this.getMPRJValidado(v);
    this.getMPrjDupli(v);

    if (v.length <= 13) {
      // mprj
      v = v.replace(/(\d{4})(\d)/, "$1.$2");
      this.formCadastro.get("FCmprj").setValue(v);
    }
    return v;
  }
  setDanos(objeto) {
    // console.log(this.checkDanoS)
    if (objeto === "Dano") {
      if (this.checkDanoS === false) {
        this.form.dano_erario = "S";
        console.log(this.formCadastro.controls.FcDano.value);
      }
      if (this.checkDanoS === true) {
        this.form.dano_erario = "N";
        console.log(this.formCadastro.controls.FcDano.value);
      }
    }

    if (objeto === "InDano") {
      this.form.inAtualVal = "S";
    }
    if (objeto === "AtualValor") {
      this.form.inCompAnal = "S";
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }
  openBottomSheet(): void {
    // this._bottomSheet.open();
  }

  mascaraMutuario(o, f) {
    this.Vobj = o;
    this.vFun = f;

    setTimeout(this.cpfCnpj(f), 4);
  }
  execmascara() {
    this.Vobj.value = this.vFun(this.Vobj.value);
  }

  cpfCnpj(v) {
    v = this.formCadastro.get("FcCnpj").value;
    const re = v;
    const str = "Apples are round, and apples are juicy.";
    const newstr = re.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/\D/g, "");

    if (v.length <= 11) {
      // CPF

      // Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2");

      // Coloca um ponto entre o terceiro e o quarto dígitos
      // de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d)/, "$1.$2");

      // Coloca um hífen entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      // // console.log("V1", v);
    } else {
      // CNPJ

      // Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");

      // Coloca ponto entre o quinto e o sexto dígitos (/^(\d{2})\.(\d{3})(\d)/, "$1 $2 $3")
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");

      // Coloca uma barra entre o oitavo e o nono dígitos ( /\.(\d{3})(\d)/ , ".$1/$2");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");

      // Coloca um hífen depois do bloco de quatro dígitos (/(\d{4})(\d)/, "$1-$2")
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    if (v.length === 18) {
      // tslint:disable-next-line: no-shadowed-variable
      let cnpjv;
      cnpjv = v.replace(/[^\d]+/g, "");
      if (cnpjv.length === 14 && v.length > 16) {
        // this.getCPFCNPJ(cnpjv);
      }
    }
    if (v.length === 14) {
      let cnpjv;
      cnpjv = v.replace(/[^\d]+/g, "");
      if (cnpjv.length === 11 && v.length <= 14) {
        // this.getCPFCNPJ(cnpjv);
      }
    }

    this.formCadastro.get("FcCnpj").setValue(v);
    return v;
  }

  public uploadFile(fileInput: any) {

    let size = fileInput.srcElement.files[0].size;
    const ext = fileInput.target.files[0].name.substr(fileInput.target.files[0].name.lastIndexOf(".") + 1);

    //const extend = ext;


    //const ext = fileInput.target.files[0].name.split(".").pop();
    //console.log("Extenção", ext[1]);
    const extend = ext;

    if (ext === "pdf" || ext === "PDF") {
      //console.log("Extenção", ext[1]);
    } else {
      this.formCadastro.get("FcArquivo").setValue("");
      const dt1 = {
        titulo: "OPS",
        status: 401,
        statusText: "Somente são permitidos arquivos .pdf",
      };
      this.msgdialogService.openDialog(dt1);
    }

    let unit = "";
    if (size < 1024) {
      size = size;
      this.fileUnit = "bytes";
      unit = "OK";
    } else if (size < 1000 * 1000) {
      size = size / 1000;
      this.fileUnit = "kB";
      unit = "OK";
    } else if (size < 1000 * 1000 * 1000) {
      size = size / 1000 / 1000;
      this.fileUnit = "MB";
      unit = "OK";
    } else {
      size = size / 1000 / 1024 / 1024;
      this.fileUnit = "GB";
    }

    size = Math.round(size);

    this.fileSize = size;
    this.fileSizeMsg = "  Tamanho do Arquivo: " + size + " " + this.fileUnit;
    if (this.fileUnit === "MB" && size > 150) {
      this.formCadastro.get("FcArquivo").setValue("");
      const dt1 = {
        titulo: "OPS",
        status: 401,
        statusText:
          "Arquivo muito gande o tamanho maximo permitido é de 150 MB. \n Arquivo atual contém " +
          this.fileSizeMsg,
      };
      this.msgdialogService.openDialog(dt1);
    } else {
      //this.formCadastro.controls.FcArquivo.invalid = false;
      this.fileData = fileInput.target.files[0];
      if (this.fileData !== null) {
        this.setProgess(this.uploadFile, 100);
      }
      console.log('File Data', this.fileData);

    }
  }
  /*  */
  public setCurrentPosition() {

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
        this.getAddress(this.latitude, this.longitude, "city");
      });
    }
  }

  onAutocompleteSelected(result: PlaceResult) {
    // console.log('onAutocompleteSelected: ', result);
  }

  onLocationSelected(location: Location) {
    // console.log('onLocationSelected: ', Location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  onGermanAddressMapped($event: GermanAddress) {
    // console.log('onGermanAddressMapped', $event);
  }

}

export interface Element {
  servico: string;
  position: number;
  opiniao: string;
}

export interface ElementTec {
  position: number;
  tecnico: string;
}
export interface Element2 {
  cpf_cnpj: string;
  position: number;
  nm_contratada: string;
  num_contratos: number;
  num_contratos_dano: number;
  val_dano: string;
}

export interface TipoContrato {
  value: string;
  viewValue: string;
}
